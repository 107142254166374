<template>
  <span class="icon">
    <LogoSvg v-if="name == 'logo'" />

    <IndoorSvg v-if="name == 'useLocation-answer1'" />
    <OutdoorSvg v-if="name == 'useLocation-answer2'" />
    <StandOperatedSvg v-if="name == 'driveType-answer1'" />
    <CabinOperatedSvg v-if="name == 'driveType-answer2'" />
    <BatterySvg v-if="name == 'fuelType-answer1'" />
    <LithBatterySvg v-if="name == 'fuelType-answer2'" />
    <DiselSvg v-if="name == 'fuelType-answer3'" />
    <GasSvg v-if="name == 'fuelType-answer4'" />
    <ModalTriggerSvg v-if="name == 'modalTrigger'" />
    <CloseModalSvg v-if="name == 'closeModal'" />

    <ModalBurdenWeightSvg v-if="name == 'modal-burdenWeight'" />
    <ModalForksLengthSvg v-if="name == 'modal-forksLength'" />
    <ModalLiftHeightSvg v-if="name == 'modal-liftHeight'" />
    <ModalLowestPointSvg v-if="name == 'modal-lowestPoint'" />
    <ModalWidthRestrictionSvg v-if="name == 'modal-widthRestriction'" />

    <LightPackageSvg v-if="name == 'lightPackage'" />
    <ComfortPackageSvg v-if="name == 'comfortPackage'" />
    <SightPackageSvg v-if="name == 'sightPackage'" />
    <SecurityPackageSvg v-if="name == 'securityPackage'" />

    <ForkliftConfiguratorSvg v-if="name == 'configuratorImage'" />
    <ForkliftContactSvg v-if="name == 'contactImage'" />

    <CarrotSvg v-if="name == 'carrot'" />
    <DoubleArrowSvg v-if="name == 'double arrow'" />
    <ConfSvg v-if="name == 'conf'" />
    <ArrowSvg v-if="name == 'arrow'" />
    <AddEquipmentSvg v-if="name == 'addEquipment'" />
  </span>
</template>

<script>
import LogoSvg from './logoSvg'
import IndoorSvg from './indoorSvg'
import OutdoorSvg from './outdoorSvg'
import StandOperatedSvg from './standOperatedSvg'
import CabinOperatedSvg from './cabinOperatedSvg'
import BatterySvg from './batterySvg'
import LithBatterySvg from './lithBatterySvg'
import DiselSvg from './diselSvg'
import GasSvg from './gasSvg'
import CarrotSvg from './carrotSvg'
import DoubleArrowSvg from './doubleArrowSvg'
import ConfSvg from './confSvg'
import ArrowSvg from './arrowSvg'
import ModalTriggerSvg from './modalTriggerSvg'
import CloseModalSvg from './closeModalSvg'
import ModalBurdenWeightSvg from './modalBurdenWeightSvg'
import ModalForksLengthSvg from './modalForksLengthSvg'
import ModalLiftHeightSvg from './modalLiftHeightSvg'
import ModalLowestPointSvg from './modalLowestPointSvg'
import ModalWidthRestrictionSvg from './modalWidthRestrictionSvg'
import LightPackageSvg from './lightPackageSvg'
import ComfortPackageSvg from './comfortPackageSvg'
import SightPackageSvg from './sightPackageSvg'
import SecurityPackageSvg from './securityPackageSvg'
import AddEquipmentSvg from './addEquipmentSvg'
import ForkliftConfiguratorSvg from './forkliftConfiguratorSvg'
import ForkliftContactSvg from './forkliftContactSvg'

export default {
  name: 'SvgPicker',
  props: ['name'],
  components: {
    LogoSvg,
    IndoorSvg,
    OutdoorSvg,
    StandOperatedSvg,
    CabinOperatedSvg,
    BatterySvg,
    LithBatterySvg,
    DiselSvg,
    GasSvg,
    CarrotSvg,
    DoubleArrowSvg,
    ConfSvg,
    ArrowSvg,
    ModalTriggerSvg,
    CloseModalSvg,
    ModalBurdenWeightSvg,
    ModalForksLengthSvg,
    ModalLiftHeightSvg,
    ModalLowestPointSvg,
    ModalWidthRestrictionSvg,
    LightPackageSvg,
    ComfortPackageSvg,
    SecurityPackageSvg,
    SightPackageSvg,
    AddEquipmentSvg,
    ForkliftConfiguratorSvg,
    ForkliftContactSvg
  }
}
</script>

<style lang="scss" scoped>
  .icon {
    display: block;
    flex-shrink: 0;
    transition: all 0.25s;

    svg {
      max-width: 100%;
      max-height: 100%;
      transition: all 0.25s;
    }
  }
</style>
