<template>
  <section>
    <template v-if="activeStep != 9">
      <button class="btn btn--big" :class="{ invisible: activeStep == 1}" @click="prevStep()">{{ $t("configurator.prevStep") }}</button>
      <button class="btn btn--big btn--primary" @click="nextStep()">{{ $t("configurator.nextStep") }}</button>
    </template>
    <template v-else>

        <button class="btn btn--big" @click="prevStep()">{{ $t('configurator.prevStep') }}</button>

      <template v-if="selectedModel.hasOwnProperty('modelName')">
          <button class="btn btn--big btn--primary" @click="openContactForm('contactTrial')">{{ $t('configurator.contactTrial') }}</button>
          <ModalTrigger name="tryOut" :hasImage="false" />

      </template>
    </template>
  </section>
</template>

<script>
import ModalTrigger from './questions/ModalTrigger.vue'
import Analytics from '@/util/googleAnalytics'

export default {
  name: 'Controls',
  components: {
    ModalTrigger
  },
  computed: {
    activeStep () {
      return this.$store.state.activeStep
    },
    selectedModel () {
      return this.$store.state.selectedModel
    }
  },
  methods: {
    nextStep () {
      Analytics.sendEvent({
        event: 'Next step'
      })
      this.$store.dispatch('nextStep')
    },
    prevStep () {
      Analytics.sendEvent({
        event: 'Previous step'
      })
      this.$store.dispatch('prevStep')
    },
    checkPrevStep () {
      this.$store.dispatch('checkPrevStep')
    },
    openContactForm (title) {
      Analytics.sendEvent({
        event: 'Contact form opened',
        data: title
      })
      this.$store.dispatch('setContactFormTitle', title)
      this.$router.push('/contact')
    }
  }
}
</script>

<style lang="scss" scoped>

.control-group-placeholder {
  flex-shrink: 0;
  width: 40px;

  @media (max-width: 767px) {
    width: 24px;
  }
}

.btn--big {
  min-width: 200px;

  @media (max-width: 991px) {
    min-width: 130px;
    padding: 15px;
  }

  @media (max-width: 767px) {
    min-width: 120px;
    padding: 15px 8px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.modal-trigger {
  display: none;
}
</style>
