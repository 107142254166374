export class AnswersModel {
  environmentLocation = {}
  environmentType = {}
  operationLift = {}
  operationBurden = {}
  operationLowest = {}
  operationWidth = {}
  deliveryForks = {}
  deliveryFuel = {}
  constructor () {
    this.environmentLocation = {
      name: 'environmentLocation',
      description: 'Umgebung',
      selected: false,
      data: {
        useLocation: []
      }
    }
    this.environmentType = {
      name: 'environmentType',
      description: 'Umgebung',
      selected: false,
      data: {
        driveType: []
      }
    }
    this.operationLift = {
      name: 'operationLift',
      description: 'Last und Hubhöhe',
      selected: false,
      data: {
        liftHeight: []
      }
    }
    this.operationBurden = {
      name: 'operationBurden',
      description: 'Last und Hubhöhe',
      selected: false,
      data: {
        burdenWeight: []
      }
    }
    this.operationLowest = {
      name: 'operationLowest',
      description: 'Last und Hubhöhe',
      selected: false,
      data: {
        lowestPoint: []
      }
    }
    this.operationWidth = {
      name: 'operationWidth',
      description: 'Last und Hubhöhe',
      selected: false,
      data: {
        widthRestriction: []
      }
    }
    this.deliveryForks = {
      name: 'deliveryForks',
      description: 'Ausstattung',
      selected: false,
      data: {
        forksLength: [],
        fuelType: []
      }
    }
    this.deliveryFuel = {
      name: 'deliveryFuel',
      description: 'Ausstattung',
      selected: false,
      data: {
        forksLength: [],
        fuelType: []
      }
    }
  }
}
