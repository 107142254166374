<template>
  <div class="select-container">
    <div class="select"
      v-for="option in options" :key="option.id"
      v-bind:class="{ active: selected.includes(option.value)}"
      v-on:click.capture="setAnswer(option)">
      <div class="image">
        <SvgPicker :name="`${question}-${option.value}`"/>
      </div>
      <span class="answer">{{ $t(`${category}.${question}.${option.value}`) }}</span>
    </div>
  </div>
</template>

<script>
import SvgPicker from '../../svg/SvgPicker'
import Analytics from '@/util/googleAnalytics'

export default {
  name: 'Select',
  props: ['category', 'question', 'options'],
  components: {
    SvgPicker
  },
  computed: {
    answers () {
      return this.$store.state.answers[this.category].data[this.question]
    }
  },
  data: function () {
    return {
      selected: []
    }
  },
  watch: {
    answers: function (data) {
      this.selected = data.map(item => item.value)
    }
  },
  methods: {
    getAnswerName (id) {
      if (id === 1) return 'Umgebung Draußen'
      if (id === 2) return 'Umgebung Drinnen'
      if (id === 6) return 'Umgebung Mitgängergerät'
      if (id === 7) return 'Umgebung Gerät mit Mitfahrmöglichkeit'
      if (id === 18) return 'Ausstattung Blei-Säure-Batterie'
      if (id === 19) return 'Ausstattung Lithium-Ionen-Batterie'
      if (id === 20) return 'Ausstattung Diesel'
      if (id === 21) return 'Ausstattung Treibgas'
    },
    setAnswer (answer) {
      let answerArray = this.answers || []

      if (this.selected.includes(answer.value)) {
        answerArray = answerArray.filter(item => item.value !== answer.value)
      } else {
        const analyticsData = {
          event: 'Answer selected',
          data: this.getAnswerName(answer.id)
        }
        Analytics.sendEvent(analyticsData)
        answerArray.push(answer)
      }

      const data = {
        category: this.category,
        question: this.question,
        answer: answerArray
      }
      this.$store.dispatch('setAnswer', data)
    }
  },
  mounted () {
    this.selected = this.answers.map(item => item.value)
  }
}
</script>

<style lang="scss" scoped>
.select-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: center;
  padding: 25px 0;

}

.select {
  width: 20vh;
  margin-right: 24px;
  cursor: pointer;
  align-items: center;
  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    width: 40% !important;
  }

  &:hover {
    .image {
      padding: 0;
    }
    .icon {
      transform: scale(1);
    }
  }

  &.active {
    .image {
      border: 2px solid #bf001f;
      padding: 0;
    }
    .icon {
      transform: scale(1);
    }
    .answer {
      color: #bf001f;
    }
  }
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid #cacaca;
  border-radius: 4px;
  background: #fff;
  transition: all 0.25s;

  @media (max-width: 767px) {
    width: 120px;
    height: 120px;
    margin-bottom: 0;
    padding: 0;
    align-items: center;
  }

  .icon {
    width: 100%;
    height: 100%;

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.answer {
  color: #666;
  font-size: 12px;
  text-transform: none;

  @media (max-width: 767px){
    margin-top: 12px;
  }
}
</style>
