
<template  v-if="activeStep < 9">
<div :class="{ 'hide' : activeStep >= 9 }">
  <section class="overviewControl" :class="{ 'active': showConf }">
    <div class="overview-controls">
      <div class="hidden-xs">
        <Controls/>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import Controls from './Controls'

export default {
  name: 'OverviewControls',
  components: {
    Controls
  },
  computed: {
    activeStep () {
      return this.$store.state.activeStep
    },
    showConf () {
      return this.$store.state.showConf
    }
  },
  methods: {
    closeConf () {
      this.$store.dispatch('toggleConf', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .overviewControl {
    grid-area: overviewControl;
    height: 15vh;
    position: relative;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #cacaca;
    background-color: rgba(0,0,0,0);
    overflow: hidden;

    @media (max-width: 1250px) {

    }

    @media (max-width: 991px) {
      height: 10vh;
    }

    @media (max-width: 767px) {
      height: 80px;
      width: 100vw;
      padding: 48px 24px 24px 24px;
      z-index: 1000;
      transition: transform 0.25s;

      &.active {
        transform: translateX(0%);
      }
    }

    &-content {
      flex-grow: 1;
      overflow: auto;
    }

    &-controls {
      flex-shrink: 0;
    }
  }

  .hidden-xs section {
    position: absolute;
    margin-left: 80px;
    -ms-transform: translateY(-50%);
    transform: translateY(10%);
    width: calc(100% - 500px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 1600px) {
    margin-left: 24px;
    }

    @media (max-width: 1401px) {
    position: inherit;
    width: calc(100% - 60px);
    }
    @media (max-width: 1250px) {
    width: calc(100% - 60px);
    }
    @media (max-width: 991px) {
    width: calc(100% - 60px);
    margin-left: 16px;
   }

    @media (max-width: 767px) {
      position: fixed;
      margin-left: 0px;
      width: 100%;
      bottom: 25px;
      left: 0;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    @media (max-width: 600px) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

</style>
