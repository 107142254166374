<template>
<transition name="modal">
  <div class="modal-background" v-if="modal.opened" @click.self="closeModal">
    <div class="modal">
      <div class="modal-header">
        <h3>{{ $t(`modal.${modal.name}.title`) }}</h3>
      </div>
      <div class="modal-body">
        <div v-if="modal.hasImage" class="modal-icon">
          <SvgPicker :name="`modal-${modal.name}`" />
        </div>
        <div class="modal-text">
          {{ $t(`modal.${modal.name}.text`) }}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn--primary btn--big btn--wide" @click="closeModal">OK</button>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
import SvgPicker from '@/components/svg/SvgPicker'

export default {
  name: 'Modal',
  components: {
    SvgPicker
  },
  computed: {
    modal () {
      return this.$store.state.modal
    }
  },
  methods: {
    closeModal () {
      this.$store.dispatch('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: relative;
  max-width: 768px;
  padding: 48px;
  border-radius: 4px;
  background-color: #fff;
  z-index: 5001;

  @media (max-width: 991px) {
    max-width: 575px;
  }

  @media (max-width: 767px) {
    max-width: 280px;
    padding: 24px;
  }

  &-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#313131, 0.3);
    z-index: 5000;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;

    @media (max-width: 767px) {
      margin-bottom: 24px;
    }

    h3 {
      font-size: 24px;

      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
  }

  &-body {
    display: flex;
    align-items: center;
    margin-bottom: 36px;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &-icon {
    flex: 0 0 auto;
    width: 130px;
    height: 130px;
    margin-right: 16px;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  &-text {
    flex: 1 1 auto;
    font-size: 18px;
    font-weight: 400;
    text-transform: none;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
  }
}

.modal-enter-active {
  animation: fade .25s ease-out;
}

.modal-leave-active {
  animation: fade .25s ease-in reverse;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
