<template>
<svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24" cy="32" r="5" fill="#BF001F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21 42C21 40.8954 21.8954 40 23 40H25.0218C25.639 40 26.2216 40.2849 26.6005 40.7721L32.12 47.8686C32.6951 48.608 33.4663 49.1709 34.3458 49.4934L41.227 52.0161C42.1629 52.3592 42.6374 53.4014 42.2816 54.3326C41.9292 55.2549 40.8959 55.7169 39.9736 55.3645L30.7667 51.8467C29.937 51.5298 29.2068 50.9975 28.6511 50.3048L26 47V64.5999L30.9693 74.0002L31.4698 74.9469L31.496 74.9963C31.4858 75.0017 31.4801 75.013 31.4819 75.0244L33.5521 88.2726C33.7653 89.6367 32.8322 90.9154 31.4681 91.1286C30.1039 91.3417 28.8252 90.4087 28.6121 89.0445L26.6588 76.5447L24.2602 72.0073L22.0961 78.355L21.7506 79.3685L21.7362 79.4109C21.7342 79.4168 21.7278 79.4199 21.7219 79.4179C21.7174 79.4164 21.7123 79.4179 21.7094 79.4217L13.4829 90.0248C12.6366 91.1157 11.0661 91.3139 9.97522 90.4675C8.88434 89.6212 8.68612 88.0507 9.53248 86.9598L16.7754 77.6244C17.1146 77.1872 17.3789 76.6966 17.5575 76.1728L21 66.0749V65.8402V42Z" fill="#BF001F"/>
<path d="M57.8812 82.9138C54.8861 82.9138 52.5327 85.1328 52.5327 87.957C52.5327 90.7813 54.8861 93.0003 57.6673 93.0003C60.6625 92.7985 63.0158 90.5795 63.0158 87.957C63.0158 85.1328 60.6625 82.9138 57.8812 82.9138ZM54.8861 87.957C54.8861 86.3432 56.1697 85.1328 57.8812 85.1328C59.5928 85.1328 60.8764 86.3432 60.8764 87.957C60.8764 89.5709 59.5928 90.7813 57.8812 90.7813C56.1697 90.7813 54.8861 89.3691 54.8861 87.957Z" fill="#212121"/>
<path d="M52.9191 65.5144V71.5663H65.9695V73.3818H50.9937V86.8977C51.2076 83.67 53.9888 81.2493 57.1979 81.0476C60.8349 80.8458 64.044 83.4683 64.258 86.8977H65.9695V90.5288H77.97C79.8955 92.3444 83.1046 92.3444 85.03 90.5288H92.0901C94.0155 92.3444 97.2247 92.3444 99.1501 90.5288H111.987V86.0908H72.6017V66.3213L65.7556 53.8141H57.1979V56.6383H63.4022L64.4719 60.8746L52.9191 65.5144Z" fill="#212121"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M40.8792 58.5216C42.3487 60.0203 44.5755 60.6964 46.8175 60.3245L60.0328 68.4738L60.6197 67.4525L47.5098 59.2128L42.5649 53.452C40.8796 54.8987 39.4299 56.8331 40.8792 58.5216ZM41.6719 56.4612C41.4411 56.8317 41.5715 57.4099 41.9125 57.8071C42.9557 58.8093 44.3349 59.3504 45.6891 59.223L42.3641 55.3494C42.0481 55.6207 41.8174 55.9913 41.6719 56.4612Z" fill="#212121"/>
</svg>
</template>

<script>
export default {
  name: 'StandOperatedSvg'
}
</script>
