<template>
<svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M64.4816 19.7818L64.4816 19.7818L64.4812 19.7911C63.2511 44.7983 53.1773 60.5542 37.8652 69.6341C22.5844 60.5589 12.7364 44.5266 11.5 19.5183V18.387L12.6068 18.2678C15.9394 18.1607 19.6066 16.4854 22.8793 14.6909C24.3999 13.8571 25.8935 12.9632 27.2803 12.1333C27.4214 12.0489 27.5613 11.9651 27.7001 11.8822C29.2204 10.9732 30.5829 10.1686 31.7605 9.57732C32.721 9.09569 33.6432 8.59948 34.4558 8.16227C34.9273 7.90855 35.3619 7.67469 35.7457 7.4751C36.7071 6.97503 37.3825 6.6724 37.8689 6.53676C38.3857 6.67641 39.1251 6.98924 40.141 7.48986C40.7718 7.80071 41.4 8.12662 42.082 8.48048C42.7308 8.81705 43.4283 9.1789 44.2231 9.57713C45.3963 10.1669 46.7409 10.9698 48.2365 11.8779C48.4165 11.9872 48.5985 12.0979 48.7823 12.2097C50.1075 13.0158 51.5273 13.8794 52.971 14.6861C56.1873 16.4829 59.7988 18.163 63.1383 18.268L64.4994 18.4015C64.5016 18.9448 64.498 19.4856 64.4816 19.7818Z" fill="#212121" stroke="#BF001F" stroke-width="3"/>
<path d="M49.1755 33.1449L38.1399 47.6873C37.744 48.2094 37.122 48.5556 36.5063 48.5556C35.8884 48.5556 35.2022 48.2538 34.7689 47.8669L26.9903 40.9187C26.7363 40.6911 26.5938 40.3829 26.5938 40.0616C26.5938 39.7403 26.7363 39.432 26.9903 39.2044L28.9122 37.4845C29.1676 37.2586 29.5126 37.1319 29.872 37.1319C30.2315 37.1319 30.5765 37.2586 30.8319 37.4845L35.8905 42.0018L44.6788 30.4179C44.8821 30.1528 45.1946 29.9703 45.5481 29.9103C45.9016 29.8503 46.2673 29.9176 46.5654 30.0976L48.8148 31.4602C49.112 31.6415 49.3167 31.9204 49.3843 32.2361C49.4519 32.5518 49.3769 32.8785 49.1755 33.1449Z" fill="white"/>
</svg>
</template>

<script>
export default {
  name: 'SecurityPackageSvg'
}
</script>
