<template>
  <section class="answer-container">
    <h3 class="answer-headline" v-if="answers.environmentLocation.selected || answers.environmentType.selected || answers.operationLift.selected || answers.operationBurden.selected || answers.operationLowest.selected || answers.operationWidth.selected || answers.deliveryForks.selected || answers.deliveryFuel.selected">{{ $t('configurator.title') }}</h3>
    <div class="answer-group-container">
      <template v-for="category in answers">
        <div :key="category.name" v-if="category.selected" class="answer-group">
          <h4 class="answer-title">{{ $t(`${category.name}.name`) }}</h4>
          <div class="answer-row">
            <div v-for="(answers, question) in category.data" :key="question">
              <span v-if="answers.length">{{ $t(`${category.name}.${question}.shortDescription`) }}</span>
              <span v-for="answer in answers" :key="answer.id" class="answer-item">{{ /^\d+/.test(answer.value) ? `${$n(answer.value.split(' ')[0])} ${answer.value.split(' ')[1]}` : $t(`${category.name}.${question}.${answer.value}`) }}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SummarySelectedInput',
  computed: {
    answers () {
      return this.$store.state.answers
    }
  }
}
</script>>

<style lang="scss" scoped>
  // There are overriders forcing PDF to render desktop
  .answer {
    &-container {
      margin-bottom: 16px;
    }

    &-headline {
      margin-bottom: 8px;
      margin-right: 0;
      font-size: 16px;
    }

    &-group-container {
      display: flex;
      text-transform: none;
      font-size: 14px;
      font-weight: 400;
    }

    &-group {
      width: 33.33%;
      padding-right: 16px;
    }

    &-title {
      margin-bottom: 8px;
    }

    &-row {
      .answer-item {
        &:after {
          content: ', ';
        }

        &:last-of-type {
          &:after {
            content: '';
          }
        }
      }
    }
  }
</style>
