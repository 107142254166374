<template>
  <section class="summary" id="summary_block">
    <div class="headline">
      <h2 class="title">{{ $t('summary.name') }}</h2>
      <p class="sub-text">NO_{{ referenceNumber }}</p>
    </div>
    <div class="model-container">
      <div class="model-details">
        <div class="model-info">
          <p class="model-name">{{ selectedModel.modelFullName }}</p>
          <p class="model-name-desc">{{ $t('summary.nameDescription') }}</p>
        </div>
        <div class="model-price-container">
          <p class="model-price">
            {{ $n(totalPrice) }}
            <template v-if="selectedModel.priceType == 'mtl'">
              {{ selectedModel.priceCurrency }} <span class="lwr">{{ $t('price.monthly') }}</span>*
            </template>
            <template v-else>
              {{ selectedModel.priceCurrency }}*
            </template>
          </p>
          <p class="sub-text lwr">
            {{ selectedModel.configurationType === 'GYR'
              ? $t('summary.priceDescription')
              : $t('summary.priceAboDescription') }}
          </p>
          <p class="sub-text lwr">{{ $t('summary.asterisk') }}</p>
          <p v-if="selectedModel.configurationType === 'ABO'" class="sub-text lwr">{{ $t('summary.specialOffer') }}</p>
        </div>
        <div class="model-specification">
          <p>{{ $t('summary.loadCapacity') }}: {{ selectedModel.tragkraft || $t('summary.noValue') }}</p>
          <p>{{ $t('summary.liftingHeight') }}: {{ selectedModel.hubgerust || $t('summary.noValue') }}</p>
          <p>{{ $t('summary.forkTines') }}: {{ selectedModel.gabelzinken || $t('summary.noValue') }}</p>
          <p v-if="selectedModel.oh">{{ $t('summary.operatingHours') }}: {{ $n(selectedModel.oh) }} {{ $t('summary.operatingHoursDesc') }}.</p>
          <p v-if="selectedModel.manufactureYear != 0">{{ $t('summary.manufactureYear') }}: {{ selectedModel.manufactureYear }}</p>
          <p v-if="selectedModel.batterie">{{ $t('summary.battery') }}: {{ selectedModel.batterie }}</p>
        </div>
        <div class="model-description">
          <template v-if="selectedModel.configurationType === 'ABO'">
            <p>{{ $t('summary.staticABOdescription') }}</p>
            <ul>
              <li v-for="item in selectedModel.description.split('\n')" :key="item">{{ item }}</li>
            </ul>
          </template>
          <template v-if="selectedModel.configurationType === 'GYR'">
            <h4>Beschreibung: </h4>
            <p>
              {{ selectedModel.description }}
            </p>
            <br>
            <template v-if="['L', 'T', 'R'].includes(selectedModel.category)">
              <p>{{ $t('summary.staticGRYdescription.typeL.text') }}</p>
              <ul v-html="$t('summary.staticGRYdescription.typeL.list')"></ul>
            </template>
            <template v-else>
              <p>{{ $t('summary.staticGRYdescription.default.text') }}</p>
              <ul v-html="$t('summary.staticGRYdescription.default.list')"></ul>
            </template>
          </template>
        </div>
      </div>
      <div class="model-image">
        <!-- <img :src="`${selectedModel.pictureLinkBig}?v=${Date.now()}`" :alt="selectedModel.modelName"> -->
        <img :src="`${selectedModel.pictureLinkBig}?v=${+new Date().getTime()}`" :alt="selectedModel.modelName" crossorigin="anonymous">
      </div>
    </div>
    <div class="mobile-controls visible-xs">
      <Controls />
    </div>
  </section>
</template>

<script>
import Controls from '../Controls'

export default {
  name: 'Summary',
  description: 'Summary',
  components: {
    Controls
  },
  computed: {
    answers () {
      return this.$store.state.answers
    },
    equipment () {
      return this.$store.state.equipment
    },
    totalPrice () {
      return this.$store.state.totalPrice
    },
    selectedModel () {
      return this.$store.state.selectedModel
    },
    contactForm () {
      return this.$store.state.contactForm
    },
    referenceNumber () {
      return this.$store.state.referenceNumber
    }
  }
}
</script>

<style lang="scss" scoped>
  .summary {
margin: auto;
  }

  .headline {
    margin-bottom: 24px;
    color: #BF001F;

    @media (max-width: 767px) {
      margin-bottom: 8px;
    }

    div:last-child {
      text-align: right;

      @media (max-width: 767px) {
        text-align: left;
        margin-top: 8px;
      }
    }
  }

  .title {
    margin-bottom: 0;
    font-size: 22px;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .sub-text {
    color: #666666;
    font-size: 12px;
    font-weight: 400;

    @media (max-width: 767px) {
      font-size: 10px;
    }
  }

  .model {
    &-container {
      display: flex;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 12px;

      @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &-name {
      font-size: 30px;
    }

    &-image {
      flex-shrink: 0;
      width: 500px;
      height: 500px;

      @media (min-width: 768px) {

        &:hover {
          transform: scale(1);
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 8px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-details {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      min-width: 50%;

      @media (max-width: 1250px) {
        max-width: 80%;
        min-width: 50%;
      }
      @media (max-width: 991px) {
        max-width: 100%;
      }

      @media (max-width: 767px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &-info {
      flex: 0;
      margin-bottom: 12px;
    }

    &-name-desc {
      color: #666;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      text-transform: none;
    }

    &-price-container {
      margin-bottom: 16px;
    }

    &-price {
      font-size: 28px;
    }

    &-specification {
      flex: 1;
      margin-bottom: 24px;

      p {
        margin-top: 0;
        text-transform: none;
      }
    }

    &-description {
      font-size: 14px;
      font-weight: 400;
      text-transform: none;

      ul {
        margin-top: 8px;
        margin-bottom: 8px;
        padding-left: 0;
        list-style-type: none;
      }

      li {
        display: flex;
        margin-bottom: 8px;

        &:before {
          content: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0L8 4L0 8L1 5L3 4L1 3L0 0Z' fill='%23BF001F'/%3E%3C/svg%3E%0A");
          margin-right: 8px;
        }
      }
    }
  }

  .information-wrapper {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  .answer {
    &-container {
      @media (max-width: 767px) {
        margin-left: 24px;
        margin-bottom: 8px;
      }
    }

    &-headline {
      margin-bottom: 24px;
      margin-right: 54px;
      font-size: 22px;

      @media (max-width: 767px) {
        margin-bottom: 16px;
        margin-right: 0;
        font-size: 12px;
      }
    }

    &-group {
      margin-bottom: 16px;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    &-title {
      margin: 0 0 8px 0;
      text-transform: uppercase;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    &-row {
      display: flex;
      flex-direction: column;
      height: 44px;
      flex-wrap: wrap;
      color: #666;
      font-size: 12px;

      div {
        margin-bottom: 8px;

        &:empty {
          display: none;
        }
      }

      span {
        &:after {
          content: ', ';
        }

        &:last-child {
          &:after {
            content: none;
          }
        }
      }
    }
  }

  .equipment {
    &-title {
      margin-bottom: 24px;
      margin-right: 54px;
      font-size: 22px;

      @media (max-width: 767px) {
        margin-bottom: 16px;
        margin-right: 0;
        font-size: 12px;
      }
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      @media (max-width: 767px) {
        margin-left: 24px;
      }
    }

    &-group {
      display: flex;
      margin-right: 36px;
      margin-bottom: 16px;
    }

    &-group-image {
      width: 60px;
      height: 60px;
      margin-right: 24px;
      padding: 4px;
      border: 1px solid #cacaca;
      border-radius: 4px;

      @media (max-width: 767px) {
      }

      .icon {
        width: 100%;
        height: 100%;
      }
    }

    &-group-text {
      margin: 0;
      font-size: 14px;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    &-group-name {
      display: block;
      margin-bottom: 16px;
      color: #666;
      font-weight: 400;
    }

    &-group-price {
      display: block;
      color: #313131;
    }
  }

</style>
