export default {
  environmentLocation: {
    name: 'EnvironmentLocation',
    description: 'Environment',
    useLocation: {
      title: 'Where would you like to use your vehicle',
      shortDescription: '',
      answer1: 'Inside',
      answer2: 'Outdoor'
    }
  },
  environmentType: {
    name: 'EnvironmentType',
    description: 'Environment',
    useLocation: {
      title: 'Where would you like to use your vehicle',
      shortDescription: '',
      answer1: 'Inside',
      answer2: 'Outdoor'
    }
  },
  operationLift: {
    name: 'Load and lifting height',
    description: 'Load and lifting height',
    liftHeight: {
      title: 'What is the maximum height of your load lifting',
      shortDescription: 'EN Hubhohe',
      placeholder: 'Enter number'
    }
  },
  operationBurden: {
    name: 'Load and lifting height',
    description: 'Load and lifting height',
    burdenWeight: {
      title: 'What is your maximum load weight',
      shortDescription: 'EN Tragkraft',
      placeholder: 'Enter number'
    }
  },
  operationLowest: {
    name: 'Load and lifting height',
    description: 'Load and lifting height',
    lowestPoint: {
      title: 'What is the lowest headroom',
      shortDescription: 'EN Tiefste durchfahrtshöhe',
      placeholder: 'Enter number'
    }
  },
  operationWidth: {
    name: 'Load and lifting height',
    description: 'Load and lifting height',
    widthRestriction: {
      title: 'Are there any restrictions in the aisle width',
      shortDescription: 'EN Arbeitsgangbreite',
      answer1: 'Yes',
      answer2: 'No'
    }
  },
  deliveryForks: {
    name: 'Furnishing',
    description: 'Furnishing',
    forksLength: {
      title: 'Please select the desired length of the forks',
      shortDescription: 'EN Gabelzinken',
      placeholder: 'Enter number'
    }
  },
  deliveryFuel: {
    name: 'Furnishing',
    description: 'Furnishing',
    fuelType: {
      title: 'Do you prefer a certain type of drive',
      shortDescription: 'EN Antriebsart',
      answer1: 'Battery',
      answer2: 'Lithium ions',
      answer3: 'Diesel',
      answer4: 'Gas'
    }
  },
  summary: {
    name: 'Summary',
    description: 'Summary',
    nameDescription: 'EN Ihre Anforderungen sind für die Modellauswahl bestmöglich berücksichtigt',
    specialOffer: 'We offer this GRUMA ABO exclusively to customers in the GRUMA sales area',
    reference: 'Reference No',
    noValue: 'Please contact sales',
    priceDescription: 'Vehicle & Additional equipment',
    priceAboDescription: 'ABO CLASSIC Vehicle & Additional equipment',
    asterisk: '*zzgl. MwSt.',
    loadCapacity: 'Load Capacity',
    liftingHeight: 'Lifting Height',
    forkTines: 'Fork Tines',
    battery: 'Battery',
    operatingHours: 'Operating hours',
    operatingHoursDesc: 'h',
    manufactureYear: 'Manufacture year',
    mobileControl: 'zusatz pakete',
    staticABOdescription: 'With this GRUMA ABO model, you benefit from the highest quality and at the same time predictable and low costs. The monthly subscription rates include all maintenance and statutory FEM tests. In addition, this GRUMA ABO model impresses with the following product features:',
    staticGRYdescription: {
      default: {
        text: 'With this GRUMA YOUNG RED model you are purchasing one of our best used forklifts. With the seal, you can directly recognize tested quality, extended guarantee and free service:',
        list: '<li>Streng geprüfter und zertifizierter Stapler inkl. gültiger UVV-Plakette</li><li>12 Monate Garantie für bis zu 1.000 Betriebsstunden</li><li>24 Monate kostenloser Service für bis zu 2.000 Betriebsstunden bei Abschluss eines Servicevertrages mit 3 Jahren Mindestlaufzeit</li><li>Jünger als 5 Jahre</li><li>14 Tage Umtauschrecht</li>'
      },
      typeL: {
        text: 'EN Mit diesem GRUMA YOUNG RED Modell erwerben Sie eines unserer besten Lagertechnikgeräte: Mit dem Siegel erkennen Sie direkt geprüfte Qualität, verlängerte Garantie und kostenlosen Service:',
        list: '<li>Streng geprüftes und zertifiziertes Gerät inkl. gültiger UVV-Plakette</li><li>12 Monate Garantie für bis zu 500 Betriebsstunden</li><li>24 Monate kostenloser Service für bis zu 1.000 Betriebsstunden bei Abschluss eines Servicevertrages mit 3 Jahren Mindestlaufzeit</li><li>Jünger als 5 Jahre</li><li>14 Tage Umtauschrecht</li>'
      }
    }
  },
  recommendations: {
    name: 'Recommendation',
    showMore: 'show more',
    showLess: 'show less',
    noResult: 'No Result'
  },
  configurator: {
    back: 'Back',
    title: 'Your Selection',
    nextStep: 'Next step',
    prevStep: 'Previous step',
    closeConf: 'Close Conf',
    contactYoungRed: 'EN GRUMA Young Red anfragen',
    contactTrial: 'Contact sales Trial',
    contactClassic: 'Contact sales',
    contactSubscription: 'Abo Flex inquire',
    equipment: 'Additional Equipment'
  },
  equipment: {
    title: 'Zusätzliche Ausrüstung',
    expandBtn: 'Show more',
    collapseBtn: 'Show less',
    notAvailable: 'There is no additional equipment for your selected model',
    lightPackage: {
      title: 'Light package',
      text: 'EN Das Lichtpaket beinhaltet das Linde VertiLight, dieses sorgt für eine großflächige, blendfreie Ausleuchtung des Arbeitsbereichs. Zwei nach vorne und einen nach hinten gerichtete LED-Arbeitsscheinwerfer sowie den Linde Bluespot. Dieses Lichtsystem sorgt für eine optimierte Sicherheit beim Rückwärtsfahren. Der Stapler wird somit von Fußgängern und anderen Fahrzeugen rechtzeitig erkannt und minimiert dadurch das Unfallrisiko.'
    },
    comfortPackage: {
      title: 'Confort Package',
      text: 'EN Das Lichtpaket beinhaltet das Linde VertiLight, dieses sorgt für eine großflächige, blendfreie Ausleuchtung des Arbeitsbereichs. Zwei nach vorne und einen nach hinten gerichtete LED-Arbeitsscheinwerfer sowie den Linde Bluespot. Dieses Lichtsystem sorgt für eine optimierte Sicherheit beim Rückwärtsfahren. Der Stapler wird somit von Fußgängern und anderen Fahrzeugen rechtzeitig erkannt und minimiert dadurch das Unfallrisiko.'
    },
    sightPackage: {
      title: 'Sight package',
      text: 'EN Das Lichtpaket beinhaltet das Linde VertiLight, dieses sorgt für eine großflächige, blendfreie Ausleuchtung des Arbeitsbereichs. Zwei nach vorne und einen nach hinten gerichtete LED-Arbeitsscheinwerfer sowie den Linde Bluespot. Dieses Lichtsystem sorgt für eine optimierte Sicherheit beim Rückwärtsfahren. Der Stapler wird somit von Fußgängern und anderen Fahrzeugen rechtzeitig erkannt und minimiert dadurch das Unfallrisiko.'
    },
    securityPackage: {
      title: 'Security package',
      text: 'Das Lichtpaket beinhaltet das Linde VertiLight, dieses sorgt für eine großflächige, blendfreie Ausleuchtung des Arbeitsbereichs. Zwei nach vorne und einen nach hinten gerichtete LED-Arbeitsscheinwerfer sowie den Linde Bluespot. Dieses Lichtsystem sorgt für eine optimierte Sicherheit beim Rückwärtsfahren. Der Stapler wird somit von Fußgängern und anderen Fahrzeugen rechtzeitig erkannt und minimiert dadurch das Unfallrisiko.'
    }
  },
  contact: {
    headline: {
      contactYoungRed: 'EN GRUMA Young Red anfragen',
      contactTrial: 'EN GRUMA Probe Abo beantragen',
      contactClassic: 'EN GRUMA Abo Classic beantragen',
      contactSubscription: 'Abo Flex inquire'
    },
    additionalText: 'EN Im GRUMA Probe Abo für <strong>0 Euro</strong> können Sie dieses Model unverbindlich und kostenlos eine Woche lang bei sich for Ort testen. Kontaktieren Sie uns über das Anfrageformular oder unter ',
    title: {
      mr: 'Mr',
      mrs: 'Mrs'
    },
    companyName: 'Company Name',
    contactPerson: 'Full name',
    function: 'Function',
    mail: 'Mail',
    phone: 'Phone',
    message: 'Message',
    referenceNumber: 'Reference Number',
    submit: 'Submit',
    back: 'Back',
    thankYou: {
      title: 'Thank you very much for your request.',
      subtitle: 'We will continue to work on this.',
      text: 'Do you wish already? The annual notice and the staple check are now also online',
      backConf: 'Back to configurator',
      backSite: 'Back to GRUMA Website',
      link: 'here'
    }
  },
  pdf: {
    headline: {
      contactTrial: 'Thank You for your interest in GRUMA Probe Abo',
      contactYoungRed: 'Thank You for your interest in GRUMA Young Red',
      contactClassic: 'Thank You for your interest in GRUMA Classic Abo',
      contactSubscription: 'Thank You for your interest in GRUMA Flex Abo'
    },
    modelName: 'Model name',
    modelPrice: 'Price',
    specifications: 'Specifications',
    otherModels: 'Other models'
  },
  modal: {
    liftHeight: {
      title: 'Lift height Title',
      text: 'Lift height detailed instructions'
    },
    burdenWeight: {
      title: 'Burden Weight Title',
      text: 'Burden Weight detailed instructions'
    },
    lowestPoint: {
      title: 'Lowest Point Title',
      text: 'Lowest Point detailed instructions'
    },
    widthRestriction: {
      title: 'Width Restriction Title',
      text: 'Width Restriction detailed instructions'
    },
    forksLength: {
      title: 'Forks length Title',
      text: 'Forks length detailed instructions'
    },
    tryOut: {
      title: 'Try out',
      text: 'EN Das GRUMA Stapler Probe Abo bietet allen Interessenten die Möglichkeit, ihren Wunsch Stapler unverbindlich und kostenlos eine Woche lang bei sich vor Ort zu testen. Am Ende des Probe Abos endet das Abo automatisch.'
    },
    classic: {
      title: 'Classic',
      text: 'EN Das GRUMA Stapler Abo Classic steht für langfristig planbare, gleichbleibend niedrige Kosten. In der monatlichen Abo Rate sind sämtliche Wartungen und gesetzlich vorgeschrieben FEM Prüfungen bereits enthalten und sorgen somit für absolute Transparenz. Sollten sich die Einsatzbedingungen einmal ändern, ändert sich die Abo- Rate ganz einfach mit.'
    },
    flex: {
      title: 'Flex',
      text: 'EN Das GRUMA Stapler Abo Flex ist die Flexible Variante, bei der Sie mit kurzer Vorankündigung jederzeit zwischen den verschiedenen Staplermodellen bedarfsgerecht wechseln können und somit z.B. für Ihr Saisongeschäft auf ein größeres Modell, oder für die Jahresinventur zu einer Hubarbeitsbühne wechseln können. Sie bezahlen jeweils immer nur für ein Fahrzeug. In der monatlichen Abo Rate ist der komplette Fullservice bereits enthalten. Außerdem ist das GRUMA Stapler Abo Flex mit einer Frist von 3 Monaten jederzeit kündbar'
    }
  },
  noModel: {
    title: 'EN Für Ihr Fahrzeug benötigen wir noch weitere Angaben.',
    subtitle: 'EN Sie haben folgende Möglichkeiten:',
    list: {
      item1: {
        text1: 'EN Lassen Sie sich direkt persönlich beraten und kontaktieren Sie unseren Vertrieb unter ',
        text2: 'Wir finden Ihr Wunschgerät.'
      },
      item2: {
        text1: 'EN Lassen Sie sich direkt persönlich beraten und kontaktieren Sie unseren Vertrieb unter ',
        text2: 'an.',
        link: 'vorherigen Schritt'
      }
    }
  },
  elements: {
    dropdown: {
      placeholder: 'Select your answer'
    }
  },
  price: {
    monthly: 'per month',
    full: 'Forklift Classic Rate'
  },
  error: {
    required: 'This field is required',
    phoneFormat: 'Incorect phone format',
    emailFormat: 'Incorect email format'
  }
}
