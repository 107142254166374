export default {
  environmentLocation: {
    name: 'Umgebung',
    description: 'Umgebung',
    useLocation: {
      title: 'Wo möchten Sie Ihr Fahrzeug nutzen',
      shortDescription: '',
      answer1: 'Drinnen',
      answer2: 'Draußen'
    }
  },
  environmentType: {
    name: 'Mobilität',
    description: 'Umgebung',
    driveType: {
      title: 'Wie soll das Fahrzeug geführt werden',
      shortDescription: '',
      answer1: 'Mitgängergerät',
      answer2: 'Gerät mit Mitfahrmöglichkeit'
    }
  },
  operationLift: {
    name: 'Hubhöhe',
    description: 'Last und Hubhöhe',
    liftHeight: {
      title: 'Wie hoch wollen Sie Ihre Last maximal heben',
      shortDescription: 'Hubhöhe: ',
      placeholder: 'eine zahl eingeben'
    }
  },
  operationBurden: {
    name: 'Lastgewicht',
    description: 'Last und Hubhöhe',
    burdenWeight: {
      title: 'Wie schwer ist Ihre Last maximal',
      shortDescription: 'Tragkraft: ',
      placeholder: 'eine zahl eingeben'
    }
  },
  operationLowest: {
    name: 'Durchfahrtshöhe',
    description: 'Last und Hubhöhe',
    lowestPoint: {
      title: 'Wie hoch ist Ihre tiefste Durchfahrtshöhe',
      shortDescription: 'Tiefste Durchfahrtshöhe: ',
      placeholder: 'eine zahl eingeben'
    }
  },
  operationWidth: {
    name: 'Arbeitsgangbreite',
    description: 'Last und Hubhöhe',
    widthRestriction: {
      title: 'Gibt es Einschränkungen in der Arbeitsgangbreite',
      shortDescription: 'Arbeitsgangbreite: ',
      answer1: 'Ja',
      answer2: 'Nein'
    }
  },
  deliveryForks: {
    name: 'Gabelzinken',
    description: 'Ausstattung',
    forksLength: {
      title: 'Bitte wählen Sie Ihre gewünschte Länge der Gabelzinken',
      shortDescription: 'Gabelzinken: ',
      placeholder: 'eine zahl eingeben'
    }
  },
  deliveryFuel: {
    name: 'Antriebsart',
    description: 'Ausstattung',
    fuelType: {
      title: 'Bevorzugen Sie eine bestimmte Antriebsart',
      shortDescription: 'Batterie: ',
      answer1: 'Blei-Säure-Batterie',
      answer2: 'Lithium-Ionen-Batterie',
      answer3: 'Diesel',
      answer4: 'Treibgas'
    }
  },
  summary: {
    name: 'Zusammenfassung',
    description: 'Zusammenfassung',
    nameDescription: 'Ihre Anforderungen sind für die Modellauswahl bestmöglich berücksichtigt',
    specialOffer: 'Dieses GRUMA ABO bieten wir ausschließlich für Kunden im GRUMA Verkaufsgebiet an',
    reference: 'Referenznummer',
    noValue: 'Alle Gabellängen verfügbar nach Rücksprache',
    priceDescription: 'Fahrzeug & Zusatzausrüstung',
    priceAboDescription: 'ABO Classic Fahrzeug & Zusatzausrüstung',
    asterisk: '*zzgl. gesetzlicher MwSt.',
    loadCapacity: 'Tragkraft',
    liftingHeight: 'Hubhöhe',
    forkTines: 'Gabelzinken',
    battery: 'Batterie',
    operatingHours: 'Betriebsstunden',
    operatingHoursDesc: 'Std',
    manufactureYear: 'Baujahr',
    mobileControl: 'zusatz pakete',
    staticABOdescription: 'Mit diesem GRUMA ABO Modell profitieren Sie von höchster Qualität bei gleichzeitig planbaren und niedrigen Kosten. In den monatlichen ABO Raten sind sämtliche Wartungen und gesetzlich vorgeschriebenen FEM-Prüfungen bereits enthalten. Darüber hinaus überzeugt dieses GRUMA ABO Modell mit folgenden Produktbesonderheiten:',
    staticGRYdescription: {
      default: {
        text: ' Mit diesem GRUMA YOUNG RED Modell erwerben Sie einen unserer besten Gebrauchtstapler. Mit dem Siegel erkennen Sie direkt geprüfte Qualität, verlängerte Garantie und kostenlosen Service:',
        list: '<li>Streng geprüfter und zertifizierter Stapler inkl. gültiger UVV-Plakette</li><li>12 Monate Garantie für bis zu 1.000 Betriebsstunden</li><li>24 Monate kostenloser Service für bis zu 2.000 Betriebsstunden bei Abschluss eines Servicevertrages mit 3 Jahren Mindestlaufzeit</li><li>Jünger als 5 Jahre</li><li>14 Tage Umtauschrecht</li>'
      },
      typeL: {
        text: 'Mit diesem GRUMA YOUNG RED Modell erwerben Sie eines unserer besten Lagertechnikgeräte: Mit dem Siegel erkennen Sie direkt geprüfte Qualität, verlängerte Garantie und kostenlosen Service:',
        list: '<li>Streng geprüftes und zertifiziertes Gerät inkl. gültiger UVV-Plakette</li><li>12 Monate Garantie für bis zu 500 Betriebsstunden</li><li>24 Monate kostenloser Service für bis zu 1.000 Betriebsstunden bei Abschluss eines Servicevertrages mit 3 Jahren Mindestlaufzeit</li><li>Jünger als 5 Jahre</li><li>14 Tage Umtauschrecht</li>'
      }
    }
  },
  recommendations: {
    name: 'Empfehlungen',
    showMore: 'Zeige mir alle Ergebnisse',
    showLess: 'Zurück zur Konfiguration',
    noResult: 'Spezialfahrzeuge'
  },
  configurator: {
    back: 'Zurück',
    title: 'Ihre Auswahl',
    nextStep: 'Nächster Schritt',
    prevStep: 'Vorheriger Schritt',
    closeConf: 'Close Conf',
    contactYoungRed: 'Gruma Young Red anfragen',
    contactTrial: 'Jetzt anfragen',
    contactClassic: 'anfragen',
    contactSubscription: 'Abo Flex anfragen'
  },
  equipment: {
    title: 'Zusätzliche Ausrüstung',
    expandBtn: 'mehr',
    collapseBtn: 'weniger',
    notAvailable: 'Für Ihr ausgewähltes Modell stehen keine Zusatzausrüstungen zur Verfügung',
    lightPackage: {
      title: 'Lichtpaket',
      text: 'Das Lichtpaket beinhaltet das Linde VertiLight, dieses sorgt für eine großflächige, blendfreie Ausleuchtung des Arbeitsbereichs. Zwei nach vorne und einen nach hinten gerichtete LED-Arbeitsscheinwerfer sowie den Linde Bluespot. Dieses Lichtsystem sorgt für eine optimierte Sicherheit beim Rückwärtsfahren. Der Stapler wird somit von Fußgängern und anderen Fahrzeugen rechtzeitig erkannt und minimiert dadurch das Unfallrisiko.'
    },
    comfortPackage: {
      title: 'Komfortpaket',
      text: 'Das Komfortpaket beinhaltet eine Vollkabine, die dem Fahrer einen idealen Schutz im Innen-und Außeneinsatz bietet. Ein hochwertiger Superkomfortsitz inkl. Sitzheizung sorgt zusammen mit der Warmwasserheizung für ein angenehmes Arbeitsumfeld bei kaltem Wetter. Das Radio mit DAB+, Bluetooth-Funktion und Freisprecheinrichtung sorgt für eine sichere Kommunikation am Arbeitsplatz.'
    },
    sightPackage: {
      title: 'Sichtpaket',
      text: 'Das Sichtpaket beinhaltet eine großzügige Panzerglas-Dachscheibe. Diese sorgt für eine helle Umgebung, hervorragende Sicht nach oben und ein großzügiges Raumgefühl. Das Panzerglas schützt zuverlässig vor herabfallenden Teilen. Dachwischer sind optional erhältlich.'
    },
    securityPackage: {
      title: 'Sicherheitspaket',
      text: 'Das Sicherheitspaket beinhaltet den Linde Safety Pilot active. Dieser hilft dem Staplerfahrer Bedien- und Fahrfehler zu vermeiden und minimiert damit die Unfallgefahr. Vorteil: Unterstützung im Grenzlastbereich, Regulierung der Hub- und Neigefunktion in Abhängigkeit von Hublast und Hubhöhe, Erhöhung der Sicherheit beim Lastenhandling.'
    }
  },
  contact: {
    headline: {
      contactYoungRed: 'anfragen',
      contactTrial: 'anfragen',
      contactClassic: 'anfragen',
      contactSubscription: 'Anfrage'
    },
    additionalText: 'Kontaktieren Sie uns über das Anfrageformular oder unter ',
    title: {
      mr: 'Herr',
      mrs: 'Frau'
    },
    companyName: 'Firma',
    contactPerson: 'Name',
    function: 'Funktion',
    mail: 'E-Mail',
    phone: 'Telefonnummer',
    message: 'Ihre Nachricht',
    referenceNumber: 'Referenz Nr',
    submit: 'Senden',
    back: 'Zurück',
    thankYou: {
      title: 'Vielen Dank für Ihre Anfrage.',
      subtitle: 'Wir werden diese umgehend bearbeiten.',
      text: 'Wussten Sie schon? Die Jährliche Unterweisung und den Staplerschein gibt es jetzt auch online. Mehr dazu',
      backConf: 'zurück zur Konfiguration',
      backSite: 'zurück zur GRUMA Website',
      link: 'hier'
    }
  },
  pdf: {
    headline: {
      contactTrial: 'Vielen Dank für Ihr Interesse an einem GRUMA Probe Abo',
      contactYoungRed: 'Vielen Dank für Ihr Interesse an einem GRUMA Young Red',
      contactClassic: 'Vielen Dank für Ihr Interesse an einem GRUMA Classic Abo',
      contactSubscription: 'Vielen Dank für Ihr Interesse an einem GRUMA Flex Abo'
    },
    modelName: 'Modellname',
    modelPrice: 'Preis',
    specifications: 'Spezifikationen',
    otherModels: 'Andere Modelle'
  },
  modal: {
    liftHeight: {
      title: 'Hubhöhe',
      text: 'Höchster Punkt, der für das Lasthandling erreicht werden muss = Oberkante Gabelzinken.Zum Beispiel: Oberkante der höchsten Regalauflage + Palette + Sicherheitsabstand von 100 mm'
    },
    burdenWeight: {
      title: 'Lastgewicht',
      text: 'Wie schwer ist die schwerste zu transportierende Last?'
    },
    lowestPoint: {
      title: 'Durchfahrtshöhe',
      text: 'Die niedrigste Durchfahrtshöhe beschreibt die tiefste Höhe, die beim Lasthandling unterfahren werden muss. Zum Beispiel: Die Unterkante des hochgefahrenen Tores oder die Einfahrtshöhe eines LKWs.'
    },
    widthRestriction: {
      title: 'Arbeitsgangbreite',
      text: 'Die Arbeitsgangbreite beschreibt den benötigten Mindest-Platzbedarf, um ein sicheres Lasthandling zwischen gegenüberliegenden Lagereinheiten, wie beispielsweise Regalen, zu gewährleisten. Sie bezieht sich auf das Standardmaß der Europalette in Längsaufnahme und enthält auch den vorgeschriebenen Sicherheitsabstand (a) von 2 x 100 mm.'
    },
    forksLength: {
      title: 'Gabelzinkenlänge',
      text: 'Die Länge der Gabelzinken oder Haubengabel / Radarme die für Ihr Lasthandling benötigt wird. Für Europaletten werden i.d.R. Gabelzinken von min. 1.150 mm bzw. 1.200 mm verwendet.'
    },
    tryOut: {
      title: 'Probe abo',
      text: 'Das GRUMA Stapler Probe Abo bietet allen Interessenten die Möglichkeit, ihren Wunsch Stapler unverbindlich und kostenlos eine Woche lang bei sich vor Ort zu testen. Am Ende des Probe Abos endet das Abo automatisch.'
    },
    classic: {
      title: 'Stapler ABO Classic',
      text: 'Das GRUMA Stapler Abo Classic steht für langfristig planbare, gleichbleibend niedrige Kosten. In der monatlichen Abo Rate sind sämtliche Wartungen und gesetzlich vorgeschrieben FEM Prüfungen bereits enthalten und sorgen somit für absolute Transparenz. Sollten sich die Einsatzbedingungen einmal ändern, ändert sich die Abo- Rate ganz einfach mit.'
    },
    flex: {
      title: 'Stapler ABO Flex',
      text: 'Das GRUMA Stapler Abo Flex ist die Flexible Variante, bei der Sie mit kurzer Vorankündigung jederzeit zwischen den verschiedenen Staplermodellen bedarfsgerecht wechseln können und somit z.B. für Ihr Saisongeschäft auf ein größeres Modell, oder für die Jahresinventur zu einer Hubarbeitsbühne wechseln können. Sie bezahlen jeweils immer nur für ein Fahrzeug. In der monatlichen Abo Rate ist der komplette Fullservice bereits enthalten. Außerdem ist das GRUMA Stapler Abo Flex mit einer Frist von 3 Monaten jederzeit kündbar'
    }
  },
  noModel: {
    title: 'Für Ihr Fahrzeug benötigen wir noch weitere Angaben.',
    subtitle: 'Sie haben folgende Möglichkeiten:',
    list: {
      item1: {
        text1: 'Lassen Sie sich direkt persönlich beraten und kontaktieren Sie unseren Vertrieb unter ',
        text2: 'Wir finden Ihr Wunschgerät.'
      },
      item2: {
        text1: 'Passen Sie Ihre Eingaben und Konfiguration im',
        text2: 'an.',
        link: 'vorherigen Schritt'
      }
    }
  },
  elements: {
    dropdown: {
      placeholder: 'Wählen Sie Ihre Antwort'
    }
  },
  price: {
    monthly: 'pro Monat',
    full: 'Stapler Abo Classic Rate'
  },
  error: {
    required: 'Dieses Feld wird benötigt',
    phoneFormat: 'Falsches Telefonformat',
    emailFormat: 'Falsches E-Mail-Format'
  }
}
