<template>
  <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.8701 0.490034L0.100098 2.27003L10.0001 12.16L19.9001 2.26003L18.1301 0.490034L10.0001 8.62003L1.8701 0.490034Z" fill="#666666"/>
  </svg>
</template>

<script>
export default {
  name: 'CarrotSvg'
}
</script>
