<template>
  <section class="questions" ref="container">
    <transition name="question">
      <EnvironmentLocation v-if="activeStep == 1" />
      <EnvironmentType v-if="activeStep ==2"/>
      <OperationLift v-if="activeStep == 3" />
      <OperationBurden v-if="activeStep == 4" />
      <OperationLowest v-if="activeStep == 5" />
      <OperationWidth v-if="activeStep == 6" />
      <DeliveryForks v-if="activeStep == 7" />
      <DeliveryFuel v-if="activeStep == 8" />
      <ModelOverview v-if="activeStep == 9" />
      <PdfTemplate v-if="activeStep == 10" />
    </transition>
  </section>

</template>

<script>
import EnvironmentLocation from './questions/EnvironmentLocation.vue'
import EnvironmentType from './questions/EnvironmentType.vue'
import OperationLift from './questions/OperationLift.vue'
import OperationBurden from './questions/OperationBurden.vue'
import OperationLowest from './questions/OperationLowest.vue'
import OperationWidth from './questions/OperationWidth.vue'
import DeliveryForks from './questions/DeliveryForks.vue'
import DeliveryFuel from './questions/DeliveryFuel.vue'
import ModelOverview from './ModelOverview.vue'
import PdfTemplate from './pdf/PdfTemplate.vue'

export default {
  name: 'Questions',
  components: {
    EnvironmentLocation,
    EnvironmentType,
    OperationLift,
    OperationBurden,
    OperationLowest,
    OperationWidth,
    DeliveryForks,
    DeliveryFuel,
    ModelOverview,
    PdfTemplate
  },
  computed: {
    activeStep () {
      return this.$store.state.activeStep
    }
  },
  methods: {
    nextStep () {
      this.$store.dispatch('nextStep')
    },
    prevStep () {
      this.$store.dispatch('prevStep')
    }
  }
}
</script>

<style scoped lang="scss">
  .questions {
    grid-area: questions;
    position: relative;
    margin-left: 80px;
    margin-right: 10px;
    padding-bottom: 40px;
    padding-top: 40px;;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;

    @media (max-width: 1600px) {
      margin-left: 24px;
    }

    @media (max-width: 991px) {
      margin-left: 16px;
      margin-right: 5px;
    }

    @media (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 8px;
      padding: 0 16px;
    }
  }

  .question-enter-active {
    animation: appear .5s ease-out;
  }

  .question-leave-active {
    animation: appear .5s ease-in reverse;
  }

  @keyframes appear {
    0% {
      position: absolute;
      left: 100%;
      width: 100%;
    }
    100% {
      position: absolute;
      left: 0%;
      width: 100%;
    }
  }
</style>
