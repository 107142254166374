<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 15.7485L3.37275 17L11 10L3.36504 3L2 4.25152L8.26992 10L2 15.7485Z" fill="#BF001F"/>
    <path d="M9 15.7485L10.3728 17L18 10L10.365 3L9 4.25152L15.2699 10L9 15.7485Z" fill="#BF001F"/>
  </svg>
</template>

<script>
export default {
  name: 'DoubleArrowSvg'
}
</script>
