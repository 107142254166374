<template>
  <section class="no-model">
    <h2 class="no-model-title">{{ $t('noModel.title') }}</h2>
    <h2 class="no-model-title">{{ $t('noModel.subtitle') }}</h2>
    <ol class="no-model-list">
      <li class="no-wrap">{{ $t('noModel.list.item1.text1') }} <a href="tel:+49821780002420" @click="sendPhoneAnalytics">0821 78 000 2420</a>. {{ $t('noModel.list.item1.text2') }}</li>
      <li class="no-wrap">{{ $t('noModel.list.item2.text1') }} <a href="javascript:;" class="margin-horizontal" @click="setStep">{{ $t('noModel.list.item2.link') }}</a> {{ $t('noModel.list.item2.text2') }}</li>
    </ol>
  </section>
</template>

<script>
import Analytics from '@/util/googleAnalytics'

export default {
  name: 'NoModel',
  methods: {
    sendPhoneAnalytics () {
      Analytics.sendEvent({
        event: 'Phone number'
      })
    },
    setStep () {
      Analytics.sendEvent({
        event: 'Change your configuration in the previous step'
      })
      this.$store.dispatch('setStep', 3)
    }
  }
}
</script>

<style lang="scss" scoped>
  .no-wrap {
    flex-wrap: wrap;
  }
  .margin-horizontal {
    margin: 0 7px;
  }

  .no-model {
    overflow: auto;

    &-title {
      margin-top: 56px;
      margin-bottom: 24px;
      font-size: 22px;

      @media (max-width: 767px) {
        display: inline-block;
        width: 100%;
        margin-top: 16px;
        font-size: 12px;
      }
    }

    &-list {
      margin: 0 0 24xp 0;
      padding-left: 28px;
      color: #666;
      font-size: 22px;
      text-transform: none;

      @media (max-width: 767px) {
        padding-left: 16px;
        font-size: 12px;
      }

      li {
        margin-bottom: 24px;

        @media (max-width: 767px) {
          margin-bottom: 16px;
        }
      }
    }
  }
</style>
