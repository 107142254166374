<template>
  <div class="input-wrapper" :class="{ active: activeState, error: errors.length }">
    <label class="input-label">{{ label }}{{ required ? '*' : '' }}</label>
    <textarea
      class="input"
      autocomplete="off"
      :name="name"
      :placeholder="placeholder"
      :value="activeValue"
      :required="required"
      rows="3"
      @change="onChange"
    ></textarea>
    <div class="input-errors" v-if="errors.length">
      <span v-for="error in errors" :key="error">{{ $t(`error.${error}`) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      activeState: false,
      activeValue: this.$props.value || '',
      hasError: false,
      errors: []
    }
  },
  watch: {
    value: function (data) {
      this.activeValue = data
    }
  },
  methods: {
    onChange (e) {
      const value = e.target.value
      this.validate(value)
      this.activeValue = value
      this.$emit('update', value)
    },
    validate (data) {
      const value = data || this.activeValue
      this.errors = []
      if (this.$props.required && value.trim() === '') {
        this.errors.push('required')
        this.$emit('error')
      }
    }
  }
}
</script>
