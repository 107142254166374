<template>
  <section>
    <form class="contact-form" action="" method="post" @submit.prevent="submit" @change="updateForm" novalidate>

      <Radio ref="title" :value="contactForm.title" name="title" :required="true" class="input-wrapper"
        :options="
        [{
          value: 'mr',
          text: $t('contact.title.mr')
        }, {
          value: 'mrs',
          text: $t('contact.title.mrs')
        }]" />
      <div class="contact-form-50">
        <Input ref="company" :value="contactForm.company" name="company" type="text" :label="$t('contact.companyName')" :placeholder="$t('contact.companyName')" :required="true" @error="onError" />
        <Input ref="contactPerson" :value="contactForm.contactPerson" name="fullName" type="text" :label="$t('contact.contactPerson')" :placeholder="$t('contact.contactPerson')" :required="true" @error="onError" />
        <Input ref="mail" :value="contactForm.mail" name="mail" type="email" :label="$t('contact.mail')" :placeholder="$t('contact.mail')" :required="true" @error="onError" />
        <Input ref="phone" :value="contactForm.phone" name="phone" type="tel" :label="$t('contact.phone')" :placeholder="$t('contact.phone')" :required="true" @error="onError" />

       </div>
       <Input ref="function" :value="contactForm.function" name="function" type="text" :label="$t('contact.function')" :placeholder="$t('contact.function')" @error="onError" />
      <Textarea ref="message" :value="contactForm.message" name="message" :label="$t('contact.message')" :placeholder="$t('contact.message')" @error="onError" />
      <div class="input-wrapper">
        <span>{{ $t('contact.referenceNumber') }}: #{{ referenceNumber }}</span>
      </div>
      <div class="controls-wrapper">
        <span @click="sendBackButtonAnalytics">
          <router-link to="/" class="btn btn--big">{{ $t('contact.back') }}</router-link>
        </span>
        <button class="btn btn--primary btn--big" type="submit">{{ $t('contact.submit') }}</button>
      </div>
    </form>
    <div class="pdf-holder">
      <PdfTemplate @generated="prepareFile" />
    </div>
  </section>
</template>

<script>
import Analytics from '@/util/googleAnalytics'
import Input from './types/Input'
import Radio from './types/Radio'
import Textarea from './types/Textarea'
import PdfTemplate from '../pdf/PdfTemplate'

export default {
  name: 'ContactForm',
  components: {
    Input,
    Radio,
    Textarea,
    PdfTemplate
  },
  data: function () {
    return {
      invalidForm: false
    }
  },
  computed: {
    contactForm () {
      return this.$store.state.contactForm
    },
    referenceNumber () {
      return this.$store.state.referenceNumber
    },
    requestType () {
      return this.$store.state.contactFormTitle
    }
  },
  methods: {
    onError () {
      this.invalidForm = true
    },
    updateForm (e) {
      const input = e.srcElement
      this.contactForm[input.name] = input.value
    },
    submit (e) {
      this.invalidForm = false
      for (const item in this.$refs) {
        this.$refs[item].validate()
      }
      if (this.invalidForm) {
        const invalidFields = []
        for (const item in this.$refs) {
          if (this.$refs[item].errors.length) invalidFields.push(item)
        }
        Analytics.sendEvent({
          event: 'Form validation error',
          data: invalidFields.join(', ')
        })
        return false
      }
      const form = new FormData(e.srcElement)
      form.append('referenceNumber', this.referenceNumber)
      form.append('requestType ', this.$t(`contact.headline.${this.requestType}`))
      form.append('file', this.contactForm.file, this.contactForm.file.name)
      Analytics.sendEvent({
        event: 'Contact form sent'
      })
      this.$store.dispatch('sendContactForm', form)
    },
    prepareFile (file) {
      this.contactForm.file = new File([file], 'file.pdf', { type: file.type })
      // this.testPdf(file)
    },
    testPdf (file) {
      const url = window.URL.createObjectURL(file)
      const element = document.createElement('a')
      element.setAttribute('href', url)
      element.setAttribute('download', 'file.pdf')
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
    sendBackButtonAnalytics () {
      Analytics.sendEvent({
        event: 'Back button form page'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .pdf-holder {
    display: none;
  }

  .contact-form {
    display: block;
    width: 100%;
  }

  .margin-right-16 {
    margin-right: 16px;
  }

  .controls-wrapper {
    margin-top: 24px;
    text-align: right;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 24px;
    }
  }

  .btn {
    & + .btn {

      @media (max-width: 767px) {
        margin-left: 0;
        margin-bottom: 16px;
      }
    }

    @media (max-width: 767px) {
      display: block;
      width: 50%;
      padding: 10px 15px;
      margin-bottom: 10px;
    }
  }

  .thank-you {
    margin-top: 10px;

    &-title {
      margin-bottom: 24px;
      font-weight: 900;
      font-size: 64px;

      @media (max-width: 767px) {
        font-size: 22px;
        font-weight: 700;
      }
    }

    &-subtitle {
      margin-bottom: 64px;
      font-weight: 900;
      font-size: 24px;

      @media (max-width: 767px) {
        font-size: 18px;
        font-weight: 500;
      }
    }

    &-text {
      max-width: 850px;
      margin-bottom: 78px;
      font-size: 22px;
      font-weight: 400;
      text-transform: none;

      @media (max-width: 767px) {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .btn--primary {
      margin-left: 50px;

      @media (max-width: 767px) {
        margin-left: 0px;
        margin-top: 12px;
      }
    }
  }
</style>
