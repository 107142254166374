<template>
<svg viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M54.3408 88.7019C54.3408 92.6677 57.9033 95.8708 62.1134 95.8708C66.3236 95.8708 69.7241 92.5151 69.7241 88.5493C69.7241 84.5835 66.1617 81.3804 61.9515 81.3804C57.7414 81.3804 54.1789 84.5835 54.3408 88.7019ZM61.9515 92.0575C59.8464 92.0575 58.3891 90.5322 58.3891 88.7019C58.3891 86.8715 60.0084 85.3462 61.9515 85.3462C63.8947 85.3462 65.514 86.8715 65.514 88.7019C65.514 90.5322 63.8947 92.0575 61.9515 92.0575Z" fill="#3E3F43"/>
<path d="M9.00009 88.7019C9.00009 92.6677 12.5625 95.8708 16.7727 95.8708C20.9829 95.8708 24.3834 92.5151 24.3834 88.5493C24.3834 84.5835 20.8209 81.3804 16.6108 81.3804C12.4006 81.3804 9.00009 84.5835 9.00009 88.7019ZM16.6108 92.0575C14.6676 92.0575 13.0483 90.5322 13.0483 88.7019C13.0483 86.8715 14.6676 85.3462 16.6108 85.3462C18.5539 85.3462 20.1732 86.8715 20.1732 88.7019C20.1732 90.5322 18.5539 92.0575 16.6108 92.0575Z" fill="#3E3F43"/>
<path d="M71.5255 36V36.6101V37.6778V83.8948V84.5049V84.6574V84.9625V86.1827H72.8209H73.6306H74.2783V84.81V84.5049V83.4372V37.6778V36.6101V36H71.5255Z" fill="#3E3F43"/>
<path d="M13.0484 80.4658C14.1819 80.1607 15.3154 79.8556 16.6109 79.8556C21.7926 79.8556 26.0028 83.8214 26.0028 88.7024C26.0028 89.3126 26.0028 89.9227 25.8408 90.5328H52.7211C52.5592 89.9227 52.5592 89.3126 52.5592 88.7024C52.5592 86.1094 53.6927 83.6689 55.7978 81.9911C59.846 78.7879 65.6755 79.2455 69.076 82.9063V38.9773L22.6023 38.9773C20.821 45.2311 13.5342 71.1614 13.5342 71.1614V74.212L13.0484 74.6696V80.4658ZM63.0846 40.8077H64.7039V77.4151H63.0846V40.8077ZM61.1414 72.9917L49.8064 40.8077H61.1414V72.9917ZM22.6023 66.738C22.6023 66.738 26.4886 44.7735 27.1363 40.9602H46.8917C47.3774 40.8077 47.7013 41.1127 47.8632 41.4178L60.1699 76.6525L50.616 76.1949L24.7073 73.6019C24.7073 73.6019 23.8977 73.6019 23.5738 73.6019H23.4119L23.7358 69.0259L22.6023 66.738ZM20.6591 71.7715C20.4972 71.619 20.0114 71.1614 19.8495 70.8563C20.6591 66.2804 25.0312 41.1127 25.0312 40.8077H26.0028C25.1931 45.3836 21.9545 64.2975 20.6591 71.7715Z" fill="#3E3F43"/>
<rect x="72.9885" y="83.3503" width="49.8516" height="2.97622" fill="#BF001F"/>
<path d="M72.9884 95.9995L77.9884 98.8863V93.1128L72.9884 95.9995ZM122.84 95.9995L117.84 93.1128V98.8863L122.84 95.9995ZM77.4884 96.4995H118.34V95.4995H77.4884V96.4995Z" fill="#BF001F"/>
</svg>

</template>

<script>
export default {
  name: 'ArrowSvg'
}
</script>
