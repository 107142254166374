<template>
<svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.1338 69.1114V57.7475C33.1338 55.8722 34.654 54.3519 36.5293 54.3519C37.4069 54.3519 38.2504 54.6917 38.8829 55.3L42.6603 58.9324H48.7975C49.5002 58.9324 50.0699 59.5021 50.0699 60.2048C50.0699 60.9075 49.5002 61.4772 48.7975 61.4772H41.0726L37.8971 58.9324V65.5487L43.318 66.4716C44.8278 66.7286 46.1365 67.6633 46.8693 69.0081L46.9993 69.2467C47.1408 69.5062 47.2589 69.7777 47.3524 70.0581L48.8208 74.4635L49.9213 76.8491C50.315 77.7026 49.9513 78.7141 49.1042 79.1214C48.2103 79.5512 47.139 79.1385 46.7644 78.2201L43.5918 70.4412L33.1338 69.1114Z" fill="#BF001F"/>
<ellipse cx="36.2179" cy="49.6201" rx="3.21785" ry="3.62008" fill="#BF001F"/>
<path d="M58.0219 85.0752C58.0219 89.363 61.8735 92.8262 66.4255 92.8262C70.9775 92.8262 74.6541 89.1981 74.6541 84.9103C74.6541 80.6225 70.8024 77.1593 66.2504 77.1593C61.6985 77.1593 57.8468 80.6225 58.0219 85.0752ZM66.2504 88.7033C63.9745 88.7033 62.3988 87.0542 62.3988 85.0752C62.3988 83.0962 64.1495 81.4471 66.2504 81.4471C68.3514 81.4471 70.1021 83.0962 70.1021 85.0752C70.1021 87.0542 68.3514 88.7033 66.2504 88.7033Z" fill="#212121"/>
<path d="M9 85.0752C9 89.363 12.8517 92.8262 17.4037 92.8262C21.9556 92.8262 25.6323 89.1981 25.6323 84.9103C25.6323 80.6225 21.7806 77.1593 17.2286 77.1593C12.6766 77.1593 9 80.6225 9 85.0752ZM17.2286 88.7033C15.1277 88.7033 13.3769 87.0542 13.3769 85.0752C13.3769 83.0962 15.1277 81.4471 17.2286 81.4471C19.3295 81.4471 21.0803 83.0962 21.0803 85.0752C21.0803 87.0542 19.3295 88.7033 17.2286 88.7033Z" fill="#212121"/>
<path d="M76.6021 81.2571V80.5974V79.443V29.4737V28.8141V28.6492V28.3193V27H78.0027H78.878H79.5784V28.4842V28.8141V29.9685V79.1338H110.38V82.3412H76.6021V81.2571Z" fill="#212121"/>
<path d="M13.377 76.1696C14.6025 75.8398 15.828 75.51 17.2286 75.51C22.8311 75.51 27.3831 79.7978 27.3831 85.075C27.3831 85.7347 27.3831 86.3944 27.208 87.054H56.2706C56.0956 86.3944 56.0956 85.7347 56.0956 85.075C56.0956 82.2715 57.3211 79.6329 59.5971 77.8188C63.974 74.3556 70.2767 74.8503 73.9534 78.8083V31.3127L23.7065 31.3127C21.7806 38.0743 13.9022 66.1098 13.9022 66.1098V69.4081L13.377 69.9029V76.1696ZM67.4755 33.2917H69.2263V72.8713H67.4755V33.2917ZM65.3746 68.0888L53.1193 33.2917H65.3746V68.0888ZM23.7065 61.3273C23.7065 61.3273 27.9083 37.5795 28.6086 33.4566H49.9679C50.4931 33.2917 50.8433 33.6216 51.0184 33.9514L59.8296 59.1787L64.3242 72.0468L53.9947 71.552L25.9824 68.7485C25.9824 68.7485 25.1071 68.7485 24.7569 68.7485H24.5818L24.932 63.801L23.7065 61.3273ZM21.6055 66.7695C21.4305 66.6046 20.9052 66.1098 20.7302 65.78C21.6055 60.8325 26.3326 33.6216 26.3326 33.2917H27.3831C26.5077 38.2392 23.0062 58.6886 21.6055 66.7695Z" fill="#212121"/>
<path d="M53.9947 71.552L64.3242 72.0468L59.8296 59.1787L55.2566 63.801L52.441 61.3273L53.9947 58.7764L52.441 57.5698L48.0164 63.801L49.6253 65.2122L51.2343 62.7988L54.8543 65.78L53.9947 71.552Z" fill="#212121"/>
</svg>
</template>

<script>
export default {
  name: 'CabinOperatedSvg'
}
</script>
