export class ContactFormModel {
  company = null
  contactPerson = null
  file = null
  mail = null
  message = null
  phone = null
  function = null

  constructor () {
    this.company = null
    this.contactPerson = null
    this.file = null
    this.mail = null
    this.message = null
    this.phone = null
    this.function = null
  }
}
