<template>
<svg width="76" height="76" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.5038 21.2932C21.0039 21.2932 14.5989 24.9355 12.0039 31.4521C14.5989 37.9687 21.0038 41.4719 28.5038 41.4719C36.0038 41.4719 42.4088 37.9687 45.0038 31.4521C42.4088 24.9355 36.0038 21.2932 28.5038 21.2932ZM28.5038 39.9411C21.3725 39.9411 12.8429 35.5491 12.8429 31.4521C12.8429 27.3551 21.2327 22.9631 28.5038 22.9631C35.775 22.9631 44.1648 27.3551 44.1648 31.4521C44.1648 35.5491 35.6351 39.9411 28.5038 39.9411Z" fill="#212121"/>
<path d="M27.8047 14.0567C27.8047 13.6724 28.1177 13.3608 28.5038 13.3608C28.89 13.3608 29.203 13.6724 29.203 14.0567V19.0665C29.203 19.4508 28.89 19.7624 28.5038 19.7624C28.1177 19.7624 27.8047 19.4508 27.8047 19.0665V14.0567Z" fill="#212121"/>
<path d="M21.3949 14.7984C21.2018 14.4656 20.7743 14.3516 20.4399 14.5437C20.1055 14.7359 19.9909 15.1614 20.1839 15.4942L22.7009 19.8329C22.894 20.1657 23.3216 20.2798 23.6559 20.0876C23.9903 19.8955 24.1049 19.4699 23.9119 19.1371L21.3949 14.7984Z" fill="#212121"/>
<path d="M36.0085 14.7402C36.2016 14.4074 36.6292 14.2934 36.9636 14.4856C37.298 14.6777 37.4125 15.1033 37.2195 15.4361L34.7025 19.7748C34.5095 20.1076 34.0819 20.2216 33.7475 20.0294C33.4131 19.8373 33.2985 19.4117 33.4916 19.0789L36.0085 14.7402Z" fill="#212121"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.364 27.6667C26.1821 27.6667 24.4208 29.4196 24.4208 31.5911C24.4208 33.7626 26.1821 35.5155 28.364 35.5155C30.5459 35.5155 32.3072 33.7626 32.3072 31.5911C32.3072 29.4196 30.5459 27.6667 28.364 27.6667Z" fill="#212121"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.364 27.6667C26.1821 27.6667 24.4208 29.4196 24.4208 31.5911C24.4208 33.7626 26.1821 35.5155 28.364 35.5155C30.5459 35.5155 32.3072 33.7626 32.3072 31.5911C32.3072 29.4196 30.5459 27.6667 28.364 27.6667Z" fill="#212121"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.364 38.1319C24.7363 38.1319 21.792 35.2017 21.792 31.5913C21.792 27.9808 24.7363 25.0506 28.364 25.0506C31.9918 25.0506 34.936 27.9808 34.936 31.5913C34.936 35.2017 31.9918 38.1319 28.364 38.1319ZM22.2376 31.5911C22.2376 34.9759 24.9822 37.723 28.364 37.723C31.7458 37.723 34.4905 34.9759 34.4905 31.5911C34.4905 28.2063 31.7458 25.4592 28.364 25.4592C24.9822 25.4592 22.2376 28.2063 22.2376 31.5911Z" fill="#212121"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.5038 39.9411C21.3725 39.9411 12.8429 35.5491 12.8429 31.4521C12.8429 27.3551 21.2327 22.9631 28.5038 22.9631C35.775 22.9631 44.1648 27.3551 44.1648 31.4521C44.1648 35.5491 35.6351 39.9411 28.5038 39.9411ZM21.792 31.5913C21.792 35.2017 24.7363 38.1319 28.364 38.1319C31.9918 38.1319 34.936 35.2017 34.936 31.5913C34.936 27.9808 31.9918 25.0506 28.364 25.0506C24.7363 25.0506 21.792 27.9808 21.792 31.5913Z" fill="#212121"/>
<path d="M15.5138 10H1V21.4583C1 21.8802 1.342 22.2222 1.76389 22.2222C2.18577 22.2222 2.52777 21.8802 2.52777 21.4583V11.5278H15.5138C15.9357 11.5278 16.2777 11.1858 16.2777 10.7639C16.2777 10.342 15.9357 10 15.5138 10Z" fill="#BF001F"/>
<path d="M15.5138 45.1387H1V33.6804C1 33.2585 1.342 32.9165 1.76389 32.9165C2.18577 32.9165 2.52777 33.2585 2.52777 33.6804V43.6109H15.5138C15.9357 43.6109 16.2777 43.9529 16.2777 44.3748C16.2777 44.7967 15.9357 45.1387 15.5138 45.1387Z" fill="#BF001F"/>
<path d="M41.4862 10H56V21.4583C56 21.8802 55.658 22.2222 55.2361 22.2222C54.8142 22.2222 54.4722 21.8802 54.4722 21.4583V11.5278H41.4862C41.0643 11.5278 40.7223 11.1858 40.7223 10.7639C40.7223 10.342 41.0643 10 41.4862 10Z" fill="#BF001F"/>
<path d="M41.4862 45.1387H56V33.6804C56 33.2585 55.658 32.9165 55.2361 32.9165C54.8142 32.9165 54.4722 33.2585 54.4722 33.6804V43.6109H41.4862C41.0643 43.6109 40.7223 43.9529 40.7223 44.3748C40.7223 44.7967 41.0643 45.1387 41.4862 45.1387Z" fill="#BF001F"/>
</svg>
</template>
<script>
export default {
  name: 'SightPackageSvg'
}
</script>
