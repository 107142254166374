<template>
  <section class="configurator">
    <h2 class="configurator-header">{{ $t('configurator.title') }}</h2>
    <div>
      <template v-for="category in answers">
        <div class="answer-section" :key="category.name">
          <h3 v-if="category.selected" class="answer-section-headline"></h3>
          <div v-for="(answer, question) in category.data" :key="question">
            <transition name="answer">
              <div class="answer-wrapper" v-if="answer.length">
                <span class="question">{{ $t(`${category.name}.${question}.title`) }}</span>
                <strong class="answer">
                  <!-- <span v-for="item in answer" :key="item">{{ typeof item === 'number' ? $t(`${category.name}.${question}.${item}`) : item }}</span> -->
                  <span v-for="item in answer" :key="item.id" :class="{ lwr: /^\d+/.test(item.value) }">
                    {{ /^\d+/.test(item.value) ? `${$n(item.value.split(' ')[0])} ${item.value.split(' ')[1]}` : $t(`${category.name}.${question}.${item.value}`) }}
                  </span>
                </strong>
                <button class="btn btn--empty" @click="removeAnswer(category.name, question)">
                  <SvgPicker name="closeModal" />
                </button>
              </div>
            </transition>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import SvgPicker from './svg/SvgPicker'
import Analytics from '@/util/googleAnalytics'

export default {
  name: 'SelectedInputs',
  components: {
    SvgPicker
  },
  computed: {
    answers () {
      return this.$store.state.answers
    }
  },
  methods: {
    removeAnswer (category, question) {
      Analytics.sendEvent({
        event: 'Delete answer',
        data: question
      })
      this.$store.dispatch('removeAnswer', { category, question })
    }
  }
}
</script>

<style scoped lang="scss">
  .configurator {
    position: relative;
  }

  .configurator-header {
    margin-bottom: 12px;
    font-size: 22px;

    @media (max-width: 1600px) {
      font-size: 18px;
    }
  }

  .answer-section-headline {
    margin-top: 10px;
    font-size: 16px;

    @media (max-width: 1600px) {
      font-size: 18px;
    }
  }

  .answer-wrapper {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr auto;
    row-gap: 8px;
    grid-template-areas:
      "question btn"
      "answer btn";
    margin-top: 8px;
    padding: 8px 16px;
    border: 1px solid #cacaca;
    border-radius: 0;

    @media (max-width: 1250px) {
      font-size: 14px;
    }

    @media (max-width: 991px) {
      padding: 8px 12px;
    }

    @media (max-width: 767px) {
      padding: 8px;
      font-size: 12px;
    }
  }

  .question {
    grid-area: question;
    margin-right: 4px;
    font-size: 14px;
    text-transform: none;
    color: #666666;
  }

  .answer {
    grid-area: answer;
    text-transform: none;
    font-size: 14px;
    span {
      &:not(:first-child):before {
        content: ', '
      }
    }
  }

  .answer-enter-active {
    animation: add .5s ease-out;
  }

  .answer-leave-active {
    animation: add .5s ease-in reverse;
  }

  @keyframes add {
    0% {
      height: 0;
      transform: translateY(-40px) rotateX(90deg);
    }
    50% {
      height: 80px;
      transform: translateY(-40px) rotateX(90deg);
    }
    100% {
      height: 80px;
      transform: translateY(0) rotateX(0deg);
    }
  }
</style>
