<template>
  <section class="input-group-wrapper">
    <SvgPicker name="configuratorImage" class="bg-image" />
    <div class="input-group">
      <h1 class="input-group-header">{{ $t("operationWidth.name") }}</h1>
      <h2 class="input-group-title">{{ $t("operationWidth.widthRestriction.title") }}?<ModalTrigger name="widthRestriction" :hasImage="true" /></h2>
      <RadioInput
        category="operationWidth"
        question="widthRestriction"
        :options="[{ id: 0, value: 'answer1' }, { id: 17, value: 'answer2' }]"
        :selectedIndex="preselectedIndex"
        @returnAction="openInput" />
      <Range v-if="showInput"
        category="operationWidth"
        question="widthRestriction"
        :id="16"
        :min="2.2"
        :max="5"
        :step="0.1"
        unit="m"
      />
    </div>
  </section>
</template>

<script>
import Range from './types/Range'
import RadioInput from './types/RadioInput'
import ModalTrigger from './ModalTrigger'
import SvgPicker from '../svg/SvgPicker'

export default {
  name: 'OperationWidth',
  description: 'Operation',
  components: {
    Range,
    RadioInput,
    ModalTrigger,
    SvgPicker
  },
  computed: {
    showInput () {
      return this.$store.state.answers.operationWidth.data.widthRestriction[0]?.id === 16
    }
  },
  data: function () {
    return {
      preselectedIndex: null
    }
  },
  mounted () {
    this.preselectedIndex = this.showInput ? 0 : null
  },
  methods: {
    openInput () {
      const data = {
        category: 'operationWidth',
        question: 'widthRestriction',
        answer: [{ id: 16, value: '5 m' }]
      }
      this.$store.dispatch('setAnswer', data)
    }
  }
}
</script>
