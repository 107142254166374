<template>
  <section class="header">
    <a href="https://www.gruma.de">
      <SvgPicker name="logo" class="logo"/>
    </a>
  </section>
</template>

<script>
import SvgPicker from './svg/SvgPicker'

export default {
  name: 'Header',
  components: {
    SvgPicker
  },
  computed: {
    locale () {
      return this.$store.state.locale
    }
  },
  methods: {
    setLocale (locale) {
      this.$store.dispatch('setLocale', locale)
    },
    backToStart () {
      if (this.$route.name !== 'Configurator') {
        this.$router.push('/')
      }
      this.$store.dispatch('setStep', 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
    grid-area: header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background-color: #BF001F;
    width: 100vw;
    z-index: 99;

    @media (max-width: 1600px) {
      padding: 8px 0;
    }

    @media (max-width: 1250px) {
      padding: 8px 24px;
    }

    @media (max-width: 991px) {
      padding: 8px 16px;
    }

    @media (max-width: 767px) {
      height: 48px;
    }
  }

  .logo {
    margin-left: 80px;
    margin-top: 8px;
    width: 180px;
    height: 56px;

    @media (max-width: 1600px) {
      margin-left: 24px;
    }

    @media (max-width: 1250px) {
      margin-left: 0;
      height: auto;
    }

    @media (max-width: 991px) {
      width: 90px;
    }

    @media (max-width: 767px) {
      width: 70px;
      margin-left: 0;
    }
  }

  .conf-cta {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .lang {
    position: absolute;
    top: 10px;
    right: 16px;
    .btn {
      padding: 8px;

      &:hover {
        color: #BF001F;
      }

      &.active {
        color: #BF001F;
      }
    }
  }
</style>
