<template>
  <section class="additional-equipment">
    <h2 class="additional-equipment-headline">{{ $t('equipment.title') }}</h2>
    <div class="selection-wrapper">
      <template v-if="showEquipment">
        <template v-for="(item, index) in equipment">
          <SelectEquipment :key="index" :data="item" :selected="selectedEquipment.includes(item.name)" />
        </template>
      </template>
      <template v-else>
        <p class="lwr">{{ $t('equipment.notAvailable') }}.</p>
      </template>
    </div>
  </section>
</template>

<script>
import SelectEquipment from './questions/types/SelectEquipment.vue'

export default {
  name: 'AdditionalEquipment',
  components: {
    SelectEquipment
  },
  computed: {
    selectedModel () {
      return this.$store.state.selectedModel
    },
    selectedEquipment () {
      return this.$store.state.equipment.map(item => item.name)
    },
    showEquipment () {
      return this.$store.state.selectedModel.additionalEquipmentShow
    },
    equipment () {
      return this.$store.state.equipmentData
    }
  }
}
</script>

<style lang="scss" scoped>
  .additional-equipment {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .additional-equipment-headline {
    flex-shrink: 0;
    margin-bottom: 36px;
    font-size: 20px;

    @media (max-width: 1600px) {
      margin-bottom: 16px;
    }
  }

  .selection-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    align-content: baseline;
    flex-wrap: wrap;
    overflow: auto;

    @media (max-width: 1600px) {

      justify-content: center;
      align-items: flex-start;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: column;
    }

  }

  .controls {
    flex-shrink: 0;

    .btn {
      display: block;
      width: 100%;
      margin-top: 16px;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
</style>
