<template>
<svg viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.2101 23.9473H9.7364V114.263H13.2101V23.9473Z" fill="#313131"/>
<path d="M123.5 23.9473H120.026V114.263H123.5V23.9473Z" fill="#313131"/>
<rect width="3.47368" height="99" transform="matrix(-1 0 0 1 11.4737 17)" fill="#313131"/>
<rect width="3.47368" height="99" transform="matrix(-1 0 0 1 123.5 17)" fill="#313131"/>
<path d="M88.7637 45.6582H108.737V65.6319H88.7637V45.6582Z" fill="#BF001F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M119.999 95.9761H103.553V111.707H119.999V95.9761ZM101.789 94.2891V113.394H121.763V94.2891H101.789Z" fill="#313131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.9464 95.9761H11.5001V111.707H27.9464V95.9761ZM9.73639 94.2891V113.394H29.7101V94.2891H9.73639Z" fill="#313131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M119.999 72.5288H103.553V88.2601H119.999V72.5288ZM101.789 70.8418V89.9471H121.763V70.8418H101.789Z" fill="#313131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.9464 72.5288H11.5001V88.2601H27.9464V72.5288ZM9.73639 70.8418V89.9471H29.7101V70.8418H9.73639Z" fill="#313131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.9464 72.5288H11.5001V88.2601H27.9464V72.5288ZM9.73639 70.8418V89.9471H29.7101V70.8418H9.73639Z" fill="#313131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.9464 49.0815H11.5001V64.8128H27.9464V49.0815ZM9.73639 47.3945V66.4998H29.7101V47.3945H9.73639Z" fill="#313131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.9464 25.7109H11.5001V42.1573H27.9464V25.7109ZM9.73639 23.9473V43.9209H29.7101V23.9473H9.73639Z" fill="#313131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M119.999 25.7109H103.553V42.1573H119.999V25.7109ZM101.789 23.9473V43.9209H121.763V23.9473H101.789Z" fill="#313131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.0913 38.71L33.1837 36.9732L36.0913 35.2363V36.6723H95.4076V35.2363L98.3152 36.9732L95.4076 38.71V37.274H36.0913V38.71Z" fill="#BF001F"/>
<path d="M61.1889 29.6437H58.9873L58.5267 30.8245H57.5001L59.647 25.7533H60.5331L62.684 30.8245H61.6534L61.1889 29.6437ZM59.2645 28.9332H60.9117L60.0881 26.8295L59.2645 28.9332Z" fill="#BF001F"/>
<path d="M66.3025 29.5183C66.3025 29.2954 66.214 29.1236 66.0371 29.0029C65.8627 28.8821 65.5465 28.7602 65.0885 28.6372C64.6305 28.5141 64.2662 28.3771 63.9955 28.2262C63.4777 27.9359 63.2187 27.5574 63.2187 27.0907C63.2187 26.682 63.4048 26.3454 63.7769 26.0807C64.1517 25.8159 64.637 25.6836 65.233 25.6836C65.6285 25.6836 65.9811 25.7486 66.2908 25.8786C66.6005 26.0087 66.8438 26.1944 67.0208 26.4359C67.1977 26.6751 67.2862 26.9409 67.2862 27.2335H66.3025C66.3025 26.9688 66.2088 26.7622 66.0215 26.6135C65.8367 26.4626 65.5713 26.3872 65.2252 26.3872C64.9025 26.3872 64.6513 26.4487 64.4718 26.5718C64.2948 26.6948 64.2063 26.8666 64.2063 27.0872C64.2063 27.273 64.3026 27.4286 64.4952 27.5539C64.6878 27.677 65.0053 27.7978 65.4477 27.9162C65.8901 28.0323 66.2453 28.1658 66.5133 28.3167C66.7814 28.4653 66.9778 28.6372 67.1027 28.8322C67.2277 29.0249 67.2901 29.2513 67.2901 29.5114C67.2901 29.934 67.108 30.2707 66.7436 30.5214C66.3819 30.7699 65.8901 30.8941 65.2681 30.8941C64.8569 30.8941 64.4783 30.8268 64.1322 30.6921C63.7887 30.5551 63.5206 30.367 63.328 30.1279C63.1381 29.8887 63.0431 29.6101 63.0431 29.292H64.0307C64.0307 29.5799 64.1374 29.8028 64.3508 29.9607C64.5642 30.1186 64.8699 30.1975 65.2681 30.1975C65.6116 30.1975 65.8692 30.136 66.041 30.0129C66.2153 29.8875 66.3025 29.7227 66.3025 29.5183Z" fill="#BF001F"/>
<path d="M72.2632 26.4638H70.491V30.8245H69.5112V26.4638H67.7546V25.7533H72.2632V26.4638Z" fill="#BF001F"/>
<path d="M72.2679 108.678C72.2679 111.768 75.0825 114.263 78.4087 114.263C81.735 114.263 84.4216 111.649 84.4216 108.559C84.4216 105.469 81.6071 102.974 78.2808 102.974C74.9545 102.974 72.14 105.469 72.2679 108.678ZM78.2808 111.292C76.6177 111.292 75.4663 110.104 75.4663 108.678C75.4663 107.252 76.7456 106.063 78.2808 106.063C79.816 106.063 81.0954 107.252 81.0954 108.678C81.0954 110.104 79.816 111.292 78.2808 111.292Z" fill="#3E3F43"/>
<path d="M36.6583 108.678C36.6583 111.768 39.4738 114.263 42.8012 114.263C46.1286 114.263 48.8162 111.649 48.8162 108.559C48.8162 105.469 46.0006 102.974 42.6732 102.974C39.3458 102.974 36.6583 105.469 36.6583 108.678ZM42.6732 111.292C41.1375 111.292 39.8577 110.104 39.8577 108.678C39.8577 107.252 41.1375 106.063 42.6732 106.063C44.209 106.063 45.4887 107.252 45.4887 108.678C45.4887 110.104 44.209 111.292 42.6732 111.292Z" fill="#3E3F43"/>
<path d="M85.2891 67.3691V67.8548V68.7048V105.495V105.981V106.102V106.345V107.317H86.5151H87.2814H87.8944V106.224V105.981V105.131V68.7048V67.8548V67.3691H85.2891Z" fill="#3E3F43"/>
<path d="M40.1309 101.951C41.0094 101.71 41.8878 101.468 42.8918 101.468C46.9076 101.468 50.1705 104.608 50.1705 108.472C50.1705 108.955 50.1705 109.438 50.045 109.921H70.877C70.7515 109.438 70.7515 108.955 70.7515 108.472C70.7515 106.419 71.63 104.487 73.2614 103.159C76.3988 100.623 80.9166 100.985 83.552 103.883V69.1055H47.5351C46.1546 74.0565 40.5074 94.5851 40.5074 94.5851V97.0003L40.1309 97.3626V101.951ZM78.9087 70.5546H80.1636V99.5362H78.9087V70.5546ZM77.4028 96.0342L68.6181 70.5546H77.4028V96.0342ZM47.5351 91.0832C47.5351 91.0832 50.547 73.6942 51.0489 70.6753H66.3592C66.7357 70.5545 66.9867 70.7961 67.1122 71.0376L76.6498 98.9324L69.2456 98.5701L49.1665 96.5173C49.1665 96.5173 48.539 96.5173 48.2881 96.5173H48.1626L48.4135 92.8946L47.5351 91.0832ZM46.0292 95.0682C45.9037 94.9474 45.5272 94.5851 45.4017 94.3436C46.0292 90.7209 49.4175 70.7961 49.4175 70.5546H50.1705C49.543 74.1773 47.0331 89.1511 46.0292 95.0682Z" fill="#3E3F43"/>
<path d="M85.2891 66.5H110.473V69.1053H85.2891V66.5Z" fill="#3E3F43"/>
</svg>
</template>

<script>
export default {
  name: 'ArrowSvg'
}
</script>
