<template>
<svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32 49.5C32 46.7386 34.2386 44.5 37 44.5H41.5H45.75H50H84C86.7614 44.5 89 46.7386 89 49.5V74.5C89 77.2614 86.7614 79.5 84 79.5H37C34.2386 79.5 32 77.2614 32 74.5V49.5Z" stroke="#212121" stroke-width="2"/>
<path d="M45.75 42.5H42.5V41H49V42.5H45.75ZM78.5 42.5H72V41H78.5V42.5Z" stroke="#212121" stroke-width="2"/>
<path d="M77.0996 60.8369H80.2725V63.1309H77.0996V66.7168H74.6826V63.1309H71.501V60.8369H74.6826V57.4004H77.0996V60.8369Z" fill="#BF001F"/>
<rect x="44" y="60.8008" width="11" height="2.4" fill="#BF001F"/>
</svg>
</template>

<script>
export default {
  name: 'BatterySvg'
}
</script>
