<template>
  <div class="equipment" :class="{ selected, expanded }" @click="toggleInfo">
    <div class="equipment-icon">
      <SvgPicker :name="data.name" />
    </div>
    <div class="equipment-info">
      <div class="equipment-image">
        <img :src="require(`@/assets/img/equipment/${data.name}.png`)" :alt="$t(`equipment.${data.name}.title`)">
      </div>
      <p class="equipment-name">{{ $t(`equipment.${data.name}.title`) }}</p>
      <p class="equipment-text">
        {{ $t(`equipment.${data.name}.text`) }}
      </p>
      <div class="equipment-footer">
        <button class="btn btn--empty">{{ $t(`equipment.${expanded ? 'collapseBtn' : 'expandBtn'}`) }}</button>
        <p>{{ $n(data.price.toFixed(0)) }} EUR</p>
      </div>
    </div>
    <button class="btn btn--empty equipment-add-btn" @click.stop="toggleEquipment">
      <SvgPicker name="addEquipment" />
    </button>
  </div>
</template>

<script>
import Analytics from '@/util/googleAnalytics'
import SvgPicker from '../../svg/SvgPicker'

export default {
  name: 'SelectEquipment',
  props: ['data', 'selected'],
  components: {
    SvgPicker
  },
  data: function () {
    return {
      expanded: false
    }
  },
  methods: {
    toggleEquipment () {
      const data = {
        name: this.data.name,
        price: this.data.price
      }
      Analytics.sendEvent({
        event: 'Selected equipment',
        data: data.name
      })
      this.$store.dispatch('toggleEquipment', data)
    },
    toggleInfo () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss" scoped>
  .equipment {
    position: relative;
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 16px;
    padding: 12px;
    border: 2px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    transition: border 0.15s;

    @media (max-width: 1600px) {
      width: 100%;
    }

    @media (max-width: 1250px) {
      padding: 8px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    &.expanded {
      border: 2px solid #bfbfbf;

      .equipment-icon {
        display: none;
      }
      .equipment-info {
        margin: 0;

        .btn {
          float: right;
        }
      }
      .equipment-image {
        display: flex;
      }
      .equipment-text {
        display: block;
      }
      .equipment-add-btn {
        position: absolute;
        top: 16px;
        right: 16px;
      }
    }

    &.selected {
      border: 2px solid #bf001f;

      .equipment-add-btn {
        transform: rotate(45deg);

        .icon>svg>path {
          fill: #666 !important;
        }
      }
    }

    &-icon {
      flex-shrink: 0;
      align-self: flex-start;
      width: 60px;
      height: 60x;
      margin-right: 16px;

      @media (max-width: 1250px) {
        width: 60px;
        height: 60px;
        margin-right: 8px;
      }

      @media (max-width: 991px) {
        width: 46px;
        height: 46px;
      }

      @media (max-width: 767px) {
        width: 56px;
        height: 56px;
        margin-right: 16px;
      }
    }

    &-info {
      margin-right: 8px;
    }

    &-image {
      display: none;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 140px;
      margin-bottom: 16px;
      border: 1px solid #cacaca;
      border-radius: 4px;

      @media (max-width: 767px) {
        flex: 1 0 auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-name {
      margin: 0 0 8px 0;

      @media (max-width: 767px) {
        margin: 0 0 4px 0;
        font-size: 12px;
      }
    }

    &-text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin: 0 0 8px 0;
      color: #666;
      font-size: 14px;
      font-weight: 400;
      text-transform: none;
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;

      p {
        margin: 0;
        color: #BF001F;
      }
    }

    &-add-btn {
      position: relative;
      transition: transform 0.25s;

      @media (max-width: 767px) {
      }
    }

    .btn {
      color: #BF001F;
    }
  }
</style>

<style lang="scss">
.equipment {
  &.selected {
    .equipment-add-btn {
      .icon svg {
        fill: #666;
      }
    }
  }
}
</style>
