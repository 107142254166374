<template>
  <button class="modal-trigger btn btn--empty" @click="openModal">
    <SvgPicker name="modalTrigger" />
  </button>
</template>

<script>
import SvgPicker from '../svg/SvgPicker'

export default {
  name: 'ModalTrigger',
  props: ['name', 'hasImage'],
  components: {
    SvgPicker
  },
  methods: {
    openModal () {
      const data = {
        name: this.name,
        hasImage: this.hasImage
      }
      this.$store.dispatch('openModal', data)
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-trigger {
    flex-shrink: 0;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 16px;
    vertical-align: text-top;

    @media (max-width: 767px) {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      vertical-align: text-bottom;
    }
  }
</style>
