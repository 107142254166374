<template>
<svg width="76" height="76" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.687 48.0001L0 9.43213H5.55943L15.4621 32.5383H41.5217L37.8683 48.0001H14.687Z" fill="#212121"/>
<ellipse cx="13.2729" cy="12.7427" rx="4.53744" ry="4.69949" transform="rotate(15.5282 13.2729 12.7427)" fill="#BF001F"/>
<path d="M11.9429 19.3692C11.7036 19.8906 11.8271 20.4893 12.0627 21.0124L15.6186 28.9066C16.0842 29.9403 17.0928 30.6232 18.2255 30.6717L29.7085 31.1636C30.5839 31.2011 31.3141 30.5016 31.3141 29.6254C31.3141 28.8191 30.6922 28.1494 29.8882 28.0899L21.9327 27.5006L18.9792 27.1531L21.2377 26.8057C19.8029 23.3918 17.9995 19.9428 17.1929 19.2139C16.8077 18.8658 15.3254 17.8239 14.0008 17.9905C12.996 18.117 12.3025 18.586 11.9429 19.3692Z" fill="#BF001F"/>
<path d="M39.96 41.8672L41.1761 37.0552L53.6176 42.2214C54.5105 42.5922 54.975 43.5814 54.6899 44.5053C54.3838 45.4974 53.3296 46.0517 52.3387 45.7416L39.96 41.8672Z" fill="#BF001F"/>
</svg>
</template>

<script>
export default {
  name: 'ComfortPackageSvg'
}
</script>
